<template>
  <!-- 固件管理——固件列表 -->
  <div class="parent">
    <div class="tabview">
      <el-input class="input_view" clearable placeholder="输入设备型号" v-model="model" size="small" />
      <el-input class="input_view" clearable placeholder="输入软件版本" v-model="softVer" size="small" />
      <el-input class="input_view" clearable placeholder="输入硬件版本" v-model="harVer" size="small" />
      <span class="date_time_str">开始时间:</span>
      <el-config-provider :locale="locale">
        <el-date-picker v-model="startTime" type="datetime" placeholder="选择开始时间" size="small"
          value-format="YYYY-MM-DD HH:mm:ss">
        </el-date-picker>
        <span class="date_time_str">结束时间:</span>
        <el-date-picker v-model="endTime" type="datetime" placeholder="选择结束时间" size="small"
          value-format="YYYY-MM-DD HH:mm:ss">
        </el-date-picker>
      </el-config-provider>
      <div class="bt_view" @click="refreshList">查询</div>
    </div>
    <el-table v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgba(255, 255, 255, 0.9)"
      @sort-change="sortChange"
      :data="dataList" style="width: 100%" :max-height="tablesHight" align="center" size="small" :header-cell-style="{
        background: '#f9f9f9',
        color: '#333333',
      }">
      <el-table-column prop="mod" label="设备型号" align="center" :width="180" sortable="custom"></el-table-column>
      <el-table-column prop="sv" label="软件版本" align="center" :width="120" sortable="custom"></el-table-column>
      <el-table-column prop="hv" label="硬件版本" align="center" :width="120" sortable="custom"></el-table-column>
      <!-- <el-table-column prop="objName" label="固件名称" align="center" :width="350"></el-table-column> -->
      <el-table-column label="固件大小" align="center" width="120" sortable="custom">
        <template #default="scope">
          <span>{{ getFirmwareSize(scope.row.len) }} </span>
        </template>
      </el-table-column>
      <el-table-column prop="desc" label="更新内容" align="center"></el-table-column>
      <el-table-column label="更新时间" align="center" :width="200">
        <template #default="scope">
          <span>{{ timeDeal(scope.row.upt) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" :width="250">
        <template #default="scope">
          <a class="operate_text" @click="showDeleteDialog(scope.row.mod, scope.row.sv)"
            style="color: red;background-color: rgba(255, 0, 0, 0.07);">删除</a>
          <a class="operate_text" @click="download(scope.row.url, '', scope.row.mod)" style="margin-left: 20px;">下载</a>
          <a class="operate_text" @click="copyDownloadUrl(scope.row.url)" style="margin-left: 20px;">复制链接</a>
        </template>
      </el-table-column>
    </el-table>
    <el-config-provider :locale="locale">
      <el-pagination class="pagination" background layout="total,prev,pager,next" :pageSize="pageSize" :total="total"
        :current-page="currentPage" @current-change="handleChange"></el-pagination>
    </el-config-provider>
  </div>
</template>
<script>
import { ElTable, ElPagination, ElButton, ElMessage, ElMessageBox, ElInput, ElDatePicker, ElConfigProvider } from "element-plus";
import commonUtil from "../util/commonUtil.js";
import Storage from "../storage/index";
import zhCn from "element-plus/lib/locale/lang/zh-cn"
import Global from "../components/ProjectGlobal";
import RequestApi from "../components/RequestApi";
import RequestManager from "../util/request";
import { fa, th, tr } from "element-plus/es/locales.mjs";
import { Message } from "@element-plus/icons-vue";
export default {
  name: "FirmwareRecords",
  setup(props, context) {
    let locale = zhCn;
    return { locale };
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      total: 0, //总数
      loading: false, //加载圈
      tablesHight: 600,
      dataList: [],
      startTime: '',
      endTime: '',
      softVer: '',
      harVer: '',
      model: '',
      logTitle: '',
      crashLog: '',
      windowHeight: 200,
    };
  },
  components: {},
  mounted() {
    let user = this.$route.query.uid;
    let token = this.$route.query.token;
    var index = Storage.getItem(Global.homeIndex);
    if (index != null) this.activeIndex = index;
    if (user != null && user.length > 0 && token != null && token.length > 0) {
      Storage.setItem(Global.token, token);
      Storage.setItem(Global.uid, user);
    }
    this.total = this.dataList.length;
    var date = new Date();
    var year = date.getFullYear();    //  返回的是年份
    var month = (date.getMonth() + 1) + "";  //  返回的月份上个月的月份，记得+1才是当月
    var dates = date.getDate() + "";       //  返回的是几号
    var newMonth = month.length == 1 ? ("0" + month) : month;
    var newDate = dates.length == 1 ? ("0" + dates) : dates;
    //开始日期默认为前90天
    const ms = 3 * 30 * 24 * 60 * 60 * 1000;
    this.startTime = commonUtil.getDateStr(new Date().getTime() - ms) + " 00:00:00";
    var endT = " 23:59:59";
    this.endTime = year + "-" + newMonth + "-" + newDate + endT;
    this.getFiemwareList();
  },
  created() {
    window.addEventListener("resize", this.getHeight); // 注册监听器
    this.getHeight(); // 页面创建时先调用一次
  },
  unmounted() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
     //全部数据排序
     sortChange(column) {
      this.currentPage = 1; // 排序后返回第一页
      this.dataList.sort(commonUtil.compare(column.prop, column.order));
    },
    getHeight() {
      //获取数据表的最大高度
      this.tablesHight = window.innerHeight - 265;
      this.windowHeight = window.innerHeight;
    },
    //获取固件列表
    getFiemwareList() {
      this.loading = true;
      RequestManager.xzRequest
        .get(RequestApi.fiemwareList, {
          params: {
            model: this.model,
            softVer: this.softVer,
            harVer: this.harVer,
            startTime: this.startTime,
            endTime: this.endTime,
            pageNo: this.currentPage,
            pageSize: this.pageSize,
            purpose: 1,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (res.data != null) {
              window.console.log(res.data);
              this.total = res.data.total;
              if (res.data.list != null && res.data.list.length > 0) {
                this.dataList = res.data.list;
              } else {
                this.dataList = [];
              }
            } else {
              this.dataList = [];
              this.total = 0;
            }
          } else {
            ElMessage.error(res.msg);
            this.dataList = [];
            this.total = 0;
          }
          this.loading = false;
        });
    },

    //删除固件
    fiemwareDelete(mod, sv) {
      this.loading = true;
      var url = RequestApi.fiemwareDelete + "?mod=" + mod + "&sv=" + sv;
      RequestManager.xzRequest
        .post(url, {})
        .then((res) => {
          if (res.state == 0) {
            ElMessage.success("删除成功");
            this.getFiemwareList()
          } else {
            ElMessage.error(res.msg);
          }
          this.loading = false;
        });
    },

    //确认删除提示
    showDeleteDialog(mod, sv) {
      ElMessageBox.confirm(
        "删除后将无法恢复，确认删除 mod=" + mod + "，sv=" + sv,
        '温馨提示',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          this.fiemwareDelete(mod, sv)
        })
        .catch(() => {

        })
    },

    //获取固件大小
    getFirmwareSize(size) {
      if (size < (1024)) { //小于1KB
        return size + "B"
      } else if (size < (1024 * 1024)) { //小于1MB
        return Math.floor(size / 1024 * 100) / 100 + "KB"
      } else {
        return Math.floor(size / 1024 / 1024 * 100) / 100 + "MB"
      }
    },

    //切换页码
    handleChange(pageNum) {
      this.currentPage = pageNum;
      this.getFiemwareList();
    },
    //点击刷新按钮
    refreshList() {
      this.currentPage = 1;
      this.getFiemwareList();
    },
    //复制
    copyDownloadUrl(textToCopy) {
      // 创建一个临时的 textarea 元素。
      const el = document.createElement('textarea');
      // 将需要复制的文本内容赋值给 textarea 元素。
      el.value = textToCopy;
      //将 textarea 元素添加到页面中。
      document.body.appendChild(el);
      //选中 textarea 元素中的文本。
      el.select();
      // 执行复制命令，将选中的文本复制到剪贴板
      document.execCommand('copy');
      //从页面中移除 textarea 元素。
      document.body.removeChild(el);
      //弹出提示框，告知用户文本已复制成功。
      ElMessage.success("已复制");
    },
    //下载文件，不直接跳转新窗口打开文件
    download(url, params, filename) {
      window.open(url, '_self');
    },
    //时间处理
    timeDeal(time) {
      var t1 = time.replace(new RegExp('T', 'g'), " ")
      return t1.substring(0, t1.lastIndexOf("."));
    }
  },
};
</script>
<style scoped lang="scss">
@import ".././assets/scss/base.scss";
@import ".././assets/css/common.css";

body {
  width: 100%;
  height: 100%;
}

.parent {
  width: calc(100% - 10px);
  margin-left: 5px;
  height: 100%;
  background-color: $colorC;
  display: flex;
  flex-direction: column;
  align-items: center;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
  overflow-y: hidden;

  .tabview {
    width: 100%;
    height: 30px;
    margin-top: 5px;
    background: white;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: auto;

    .input_view {
      margin-left: 5px;
      height: 20px;
      font-size: 10px;
      width: 100px;
    }

    .bt_view {
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
      height: 20px;
      line-height: 20px;
      border-radius: 2px;
      color: #ffffff;
      background-color: $colorA;
      font-size: 10px;
      cursor: pointer;
    }

    .bt_view:hover {
      background-color: $colorB;
    }

    .date_time_str {
      font-size: 10px;
      margin-left: 5px;
      margin-right: 5px;
    }

  }

  .operate_text {
    color: $colorA;
    cursor: pointer;
    background-color: $colorE;
    font-size: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 2px;
  }

  .pagination {
    width: 98%;
    text-align: right;
    margin-top: 10px;
    font-size: 10px;
    justify-content: flex-end;
  }

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  html {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }

  body::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  body::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  body::-webkit-scrollbar-track {
    background-color: transparent;
  }

  body {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }

}
</style>
<style>
/* 此处禁止改动，会影响到所有使用时间选择器的地方 */
.el-date-editor.el-input,
.el-date-editor.el-input__wrapper {
  width: 183px !important;
  height: 20px !important;
}
</style>
