<template>
  <div class="parent">
    <div class="tap_option">
      <el-button
        class="btn_tab"
        type="primary"
        icon="el-icon-refresh-right"
        size="small"
        plain
        @click="refreshList"
      >
        查询</el-button
      >
      <el-button
        class="btn_tab"
        type="primary"
        icon="el-icon-printer"
        size="small"
        plain
        @click="showDrawer(null)"
      >
        新建</el-button
      >
    </div>
    <div class="content" v-loading="loading">
      <div class="list_view" v-for="(item, index) in dataList" :key="index">
        <span>角色ID: {{ item.roleId }}</span>
        <span>角色名: {{ item.name }}</span>
        <div class="item_option">
          <span class="detail" @click="showDrawer(item)">编辑</span>
          <span class="delete" @click="deleteRoleDialog(item)">删除</span>
        </div>
      </div>
    </div>
    <el-drawer
      title=""
      v-model="drawerVisible"
      :with-header="false"
      direction="rtl"
      size="25%"
    >
      <div class="drawer_view" v-loading="drawerLoading">
        <div class="drawer_top">
          <span class="drawer_title">{{ drawerTitle }}</span>
          <div class="drawer_top_right">
            <span @click="drawerVisible = false">取消</span>
            <span @click="commit">提交</span>
          </div>
        </div>
        <div class="drawer_item" v-if="optionType == 1">
          <span>角色ID：</span>
          <el-input
            class="drawer_item_input"
            v-model="roleId"
            size="small"
            :disabled="roleIdDisable"
          ></el-input>
        </div>
        <div class="drawer_item">
          <span>角色名：</span>
          <el-input
            class="drawer_item_input"
            v-model="roleName"
            size="small"
          ></el-input>
        </div>
        <div class="tree_item">
          <span class="tree_title">授权菜单：</span>
          <el-tree
            class="tree_view"
            :data="showMenuList"
            show-checkbox
            default-expand-all
            node-key="menuId"
            ref="tree"
            highlight-current
            :props="defaultProps"
          >
          </el-tree>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { ElDrawer,ElButton,ElTree,ElInput,ElMessage,ElMessageBox } from "element-plus";
import Global from "./../../components/ProjectGlobal";
import Storage from "../../storage/index";
import RequestApi from "./../../components/RequestApi";
// import Utils from "../../util/utils";
import RequestManager from "../../util/request";
export default {
  name: "RoleManagement",
  data() {
    return {
      loading: false, //加载圈
      drawerLoading: false, //加载圈
      dataList: [], //角色列表
      menuList: [], //所有菜单列表
      showMenuList: [], //菜单授权栏的所有菜单列表
      roleMenuList: [], //某个角色的菜单列表
      defaultProps: {
        children: "children",
        label: "menuName",
      },
      drawerVisible: false, //是否显示侧边弹框
      roleIdDisable: false,
      roleId: "",
      roleName: "",
      drawerTitle: "",
      optionType: 0, //0-新增 1-编辑
    };
  },
  components: { ElDrawer,ElButton,ElTree,ElInput },
  created() {},
  unmounted() {},
  mounted() {
    this.controlCrumb();
    this.findAllRole();
    this.findAllMenu();
  },
  methods: {
    //查询所有角色
    findAllRole() {
      this.loading = true;
      RequestManager.hxCloudRequest
        .post(RequestApi.findAllRole, {
          user: Storage.getItem(Global.uid),
          token: Storage.getItem(Global.token),
        })
        .then((res) => {
          if (res.code == 200) {
            this.dataList = res.data;
          } else {
            this.dataList = [];
            ElMessage.error(res.message);
          }
          this.loading = false;
        });
    },
    //查询某个角色的菜单信息
    findRoleMenu(roleId) {
      this.drawerLoading = true;
      RequestManager.hxCloudRequest
        .post(RequestApi.findRoleMenu, {
          user: Storage.getItem(Global.uid),
          token: Storage.getItem(Global.token),
          roleId: roleId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.roleMenuList = res.data;
          } else {
            this.roleMenuList = [];
            ElMessage.error(res.message);
          }
          this.drawerLoading = false;
          //设置授权的菜单
          var arrays = [];
          for (var i = 0; i < this.roleMenuList.length; i++)
            arrays.push(this.roleMenuList[i].menuId);
          this.$refs.tree.setCheckedKeys(arrays);
        });
    },
    //查询所有菜单
    findAllMenu() {
      RequestManager.hxCloudRequest
        .post(RequestApi.findAllMenu, {
          user: Storage.getItem(Global.uid),
          token: Storage.getItem(Global.token),
        })
        .then((res) => {
          var tempList = [];
          if (res.code == 200) {
            tempList = res.data;
            var parentList = [];
            if (tempList != null && tempList.length > 0) {
              for (var i = 0; i < tempList.length; i++) {
                if (tempList[i].parentMenuId == -1) {
                  parentList.push(tempList[i]);
                }
              }
              for (var k = 0; k < tempList.length; k++) {
                for (var j = 0; j < parentList.length; j++) {
                  if (tempList[k].parentMenuId == parentList[j].menuId) {
                    if (parentList[j]["children"] == null) {
                      parentList[j]["children"] = [tempList[k]];
                    } else {
                      parentList[j].children.push(tempList[k]);
                    }
                  }
                }
              }
              this.menuList = parentList;
            } else {
              this.menuList = [];
            }
          } else {
            this.menuList = [];
            ElMessage.error(res.message);
          }
          this.showMenuList = this.menuList;
        });
    },
    //显示侧边弹框
    showDrawer(bean) {
      this.roleId = "";
      this.roleName = "";
      if (bean != null) {
        this.optionType = 1;
        this.drawerTitle = "编辑角色";
        this.roleId = bean.roleId;
        this.roleName = bean.name;
        this.roleIdDisable = true;
        this.findRoleMenu(bean.roleId);
      } else {
        this.showMenuList = this.menuList;
        if (this.$refs.tree != null) this.$refs.tree.setCheckedKeys([-1000]);
        this.optionType = 0;
        this.drawerTitle = "新增角色";
        this.roleIdDisable = false;
      }
      this.drawerVisible = true;
    },
    //编辑或增加角色
    commit() {
      if (this.roleName == "") {
        ElMessage.warning("请输入角色名称");
        return;
      }
      var menuIdCheckArray = this.$refs.tree.getCheckedKeys(); //授权的菜单id数据
      var menuIds = "";
      for (var k = 0; k < menuIdCheckArray.length; k++)
        menuIds = menuIds + menuIdCheckArray[k] + ",";
      if (menuIds.lastIndexOf(",") != -1)
        menuIds = menuIds.substring(0, menuIds.lastIndexOf(","));
      if (this.roleId.length > 0) this.editRoleMenu(this.roleId, menuIds);
      this.drawerLoading = true;
      RequestManager.hxCloudRequest
        .post(RequestApi.addOrEditRole, {
          user: Storage.getItem(Global.uid),
          token: Storage.getItem(Global.token),
          name: this.roleName,
          roleId: this.roleId,
        })
        .then((res) => {
          if (res.code == 200) {
            ElMessage.success("提交成功");
            this.editRoleMenu(res.data, menuIds);
            this.findAllRole();
            this.drawerVisible = false;
          } else {
            ElMessage.error(res.message);
          }
          this.drawerLoading = false;
        });
    },
    //编辑角色绑定的菜单
    editRoleMenu(roleId, menuIds) {
      RequestManager.hxCloudRequest
        .post(RequestApi.editRoleMenu, {
          user: Storage.getItem(Global.uid),
          token: Storage.getItem(Global.token),
          roleId: roleId,
          menuIds: menuIds,
        })
        .then((res) => {
          if (res.code != 200) ElMessage.error(res.message);
        });
    },
    //删除角色
    deleteRole(roleId) {
      RequestManager.hxCloudRequest
        .post(RequestApi.deleteRole, {
          user: Storage.getItem(Global.uid),
          token: Storage.getItem(Global.token),
          roleId: roleId,
        })
        .then((res) => {
          if (res.code == 200) {
            ElMessage.success("删除成功");
            this.findAllRole();
          } else {
            ElMessage.error(res.message);
          }
        });
    },
    //删除角色
    deleteRoleDialog(item) {
      ElMessageBox.confirm(
        "确认删除'" + item.name + "' 角色? 将同步删除用户关联的该角色数据",
        "提示",
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          customClass: "custom-box",
        }
      )
        .then(() => {
          this.deleteRole(item.roleId);
        })
        .catch(() => {});
    },
    //设置面包屑文案
    controlCrumb() {
      this.$emit("controlCrumb", "首页", "权限管理", "角色管理");
    },
    //点击刷新按钮
    refreshList(e) {
      let target = e.target;
      // 根据button组件内容 里面包括一个span标签，如果设置icon，则还包括一个i标签，其他情况请自行观察。
      // 所以，在我们点击到button组件上的文字也就是span标签上时，直接执行e.target.blur()不会生效，所以要加一层判断。
      if (target.nodeName == "SPAN" || target.nodeName == "I") {
        target = e.target.parentNode;
      }
      target.blur();
      this.currentPage = 1;
      this.findAllRole();
    },
    //跳转至首页
    navToHome() {
      window.location.href = "http://htopcloud.chinaoak.cn:8080/summary.aspx";
    },
  },
};
</script>
<style  lang="scss" scoped>
body {
  margin: 0;
}

.parent {
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-top: 10px;
  overflow: hidden;

  .tap_option {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
  }

  .content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap; //换行属性
    flex-direction: row;
    .list_view {
      width: 150px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      background-color: white;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
      border-radius: 5px;
      padding-left: 20px;
      padding-top: 20px;
      padding-right: 20px;
      padding-bottom: 10px;
      margin-top: 10px;
      margin-right: 10px;
      flex-wrap: wrap; //换行属性

      span {
        color: #333333;
        font-size: 14px;
        margin-bottom: 10px;
      }
      .item_option {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;
        .detail {
          color: white;
          background-color: #02ace5;
          border-radius: 4px;
          padding-top: 3px;
          padding-bottom: 3px;
          padding-left: 13px;
          padding-right: 13px;
          cursor: pointer;
        }
        .detail:hover {
          background-color: #0294c5;
        }
        .delete {
          color: white;
          background-color: #fa0f0f;
          border-radius: 4px;
          padding-top: 3px;
          padding-bottom: 3px;
          padding-left: 13px;
          padding-right: 13px;
          cursor: pointer;
          margin-left: 10px;
        }
        .delete:hover {
          background-color: #e00d0d;
        }
      }
    }
  }

  .el-button--primary {
    color: #02ade5 !important;
    background-color: #ffffff;
    border-color: #02ade5;

    &:hover {
      background-color: #02ace5;
      color: white !important;
    }
  }

  .drawer_view {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .drawer_top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      margin-top: 10px;
      overflow: hidden;

      .drawer_title {
        color: #333333;
        font-weight: bold;
        font-size: 15px;
        margin-left: 10px;
        overflow: hidden;
      }

      .drawer_top_right {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        align-items: center;
        margin-right: 10px;

        span {
          color: white;
          background-color: #00a4db;
          border-radius: 4px;
          padding-top: 5px;
          padding-bottom: 5px;
          padding-left: 16px;
          padding-right: 16px;
          cursor: pointer;
          margin-left: 10px;
        }
        span:hover {
          background-color: #0097c9;
        }
      }
    }

    .drawer_item {
      width: 100%;
      display: flex;
      flex-direction: row;
      overflow: hidden;
      align-items: center;
      margin-top: 10px;

      span {
        color: #333333;
        font-size: 13px;
        margin-left: 10px;
        width: 70px;
      }

      .drawer_item_input {
        flex: 1;
        margin-right: 10px;
      }
    }

    .tree_item {
      width: 100%;
      display: flex;
      flex-direction: row;
      overflow: hidden;
      margin-top: 10px;

      span {
        color: #333333;
        font-size: 13px;
        margin-top: 6px;
        margin-left: 10px;
        width: 70px;
      }
    }
  }
}
</style>
<style>
/* el-tab 样式修改 */
.el-tabs__item.is-active {
  color: #02ace5;
}

.el-tabs__item:hover {
  color: #02ace5;
}

.el-tabs__active-bar {
  background-color: #02ace5;
}
</style>