import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/PrivacyAgreement',
    name: 'PrivacyAgreement',
    component: () => import('../views/PrivacyAgreement.vue')
  },
  {
    path: '/UserAgreement',
    name: 'UserAgreement',
    component: () => import('../views/UserAgreement.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: 'DeviceList',
    children: [
      {
        path: '/DeviceList',
        name: 'DeviceList',
        component: () => import('../views/DeviceList.vue')
      },
      
      {
        path: '/DeviceDebugData',
        name: 'DeviceDebugData',
        component: () => import('../views/DeviceDebugData.vue')
      },

      {
        path: '/SysMenuManage',
        name: 'SysMenuManage',
        component: () => import('../views/SysMenuManage.vue')
      },
      {
        path: '/SysOrganizationalManage',
        name: 'SysOrganizationalManage',
        component: () => import('../views/SysOrganizationalManage.vue')
      },
      {
        path: '/SysRoleManage',
        name: 'SysRoleManage',
        component: () => import('../views/SysRoleManage.vue')
      },
      {
        path: '/SysUserManage',
        name: 'SysUserManage',
        component: () => import('../views/SysUserManage.vue')
      },
      {
        path: '/FirmwareManagement',
        name: 'FirmwareManagement',
        component: () => import('../views/FirmwareManagement.vue')
      },
      {
        path: '/FirmwareManagementPTP',
        name: 'FirmwareManagementPTP',
        component: () => import('../views/FirmwareManagementPTP.vue')
      },
      {
        path: '/FirmwareRecords',
        name: 'FirmwareRecords',
        component: () => import('../views/FirmwareRecords.vue')
      },
      {
        path: '/FirmwareRecordsPTP',
        name: 'FirmwareRecordsPTP',
        component: () => import('../views/FirmwareRecordsPTP.vue')
      },
      {
        path: '/AppPackageUpload',
        name: 'AppPackageUpload',
        component: () => import('../views/AppPackageUpload.vue')
      },
      {
        path: '/AppPackageRecords',
        name: 'AppPackageRecords',
        component: () => import('../views/AppPackageRecords.vue')
      },
      {
        path: '/MatterOtaFileUpload',
        name: 'MatterOtaFileUpload',
        component: () => import('../views/MatterOtaFileUpload.vue')
      },
      {
        path: '/MatterOtaFileRecords',
        name: 'MatterOtaFileRecords',
        component: () => import('../views/MatterOtaFileRecords.vue')
      },
      {
        path: '/UserList',
        name: 'UserList',
        component: () => import('../views/UserList.vue')
      },
      {
        path: '/RoleManagement',
        name: 'RoleManagement',
        component: () => import('../views/permissionManagement/RoleManagement.vue'),
        meta: { secTitle: 'RoleManagement', keepAlive: true }
      },
      {
        path: '/MenuManagement',
        name: 'MenuManagement',
        component: () => import('../views/permissionManagement/MenuManagement.vue'),
        meta: { secTitle: 'MenuManagement', keepAlive: true }
      },
      {
        path: '/UserManagement',
        name: 'UserManagement',
        component: () => import('../views/permissionManagement/UserManagement.vue'),
        meta: { secTitle: 'UserManagement', keepAlive: true }
      },
    ]
  },
]


const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
