<template>
  <!-- 固件管理——固件列表 -->
  <div class="parent">
    <div class="tabview">
      <div class="bt_view" @click="refreshList">查询</div>
    </div>
    <el-table v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgba(255, 255, 255, 0.9)"
      :data="dataList.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        " style="width: 100%" :max-height="tablesHight" align="center" size="small" :header-cell-style="{
          background: '#f9f9f9',
          color: '#333333',
        }">
      <el-table-column prop="name" label="APP名称" align="center" width="90"></el-table-column>
      <el-table-column prop="version" label="软件版本" align="center" width="90"></el-table-column>
      <el-table-column prop="minCompatibleVer" label="最低兼容版本" align="center" width="110"></el-table-column>
      <el-table-column label="系统类型" align="center" width="90">
        <template #default="scope">
          <span>{{ getPlatform(scope.row.clientType) }} </span>
        </template>
      </el-table-column>
      <el-table-column label="分发平台" align="center" width="90">
        <template #default="scope">
          <span>{{ getPublishWay(scope.row.publishWay) }} </span>
        </template>
      </el-table-column>
      <el-table-column label="更新策略" align="center" width="130">
        <template #default="scope">
          <span>{{ getUpdatePolicy(scope.row.updatePolicy) }} </span>
        </template>
      </el-table-column>
      <el-table-column label="更新内容" align="center">
        <template #default="scope">
          <span style="white-space: pre-wrap">{{ scope.row.updateContent }} </span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="发布时间" align="center">
        <template #default="scope">
          <span>{{ timeDeal(scope.row.publishTime) }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="状态" align="center" width="70">
        <template #default="scope">
          <span :style="{ color: scope.row.enable ? '#FFA71A' : '#999999' }">{{ scope.row.enable ? "已发布" : "未发布" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" :width="320">
        <template #default="scope">
          <a class="operate_text" @click="showTakeEffectDialog(scope.row.enable, scope.row.id)">{{
            scope.row.enable ? "失效" : "发布" }}</a>
          <a class="operate_text" @click="download(scope.row.url, '', scope.row.name)" style="margin-left: 15px;">下载</a>
          <a class="operate_text" @click="copyDownloadUrl(scope.row.url)" style="margin-left: 15px;">复制链接</a>
          <a class="operate_text" @click="showDeleteDialog(scope.row.enable, scope.row.id)"
            style="margin-left: 15px;color: red;background-color: rgba(255, 0, 0, 0.07);">删除</a>
        </template>
      </el-table-column>
    </el-table>
    <el-config-provider :locale="locale">
      <el-pagination class="pagination" background layout="total,prev,pager,next" :pageSize="pageSize" :total="total"
        :current-page="currentPage" @current-change="handleChange"></el-pagination>
    </el-config-provider>
  </div>
</template>
<script>
import { ElTable, ElPagination, ElButton, ElMessage, ElMessageBox, ElInput, ElDatePicker, ElConfigProvider } from "element-plus";
import commonUtil from "../util/commonUtil.js";
import Storage from "../storage/index";
import zhCn from "element-plus/lib/locale/lang/zh-cn"
import Global from "../components/ProjectGlobal";
import RequestApi from "../components/RequestApi";
import RequestManager from "../util/request.js";
import { el, fa, th, tr } from "element-plus/es/locales.mjs";
import { Message } from "@element-plus/icons-vue";
export default {
  name: "AppPackageRecords",
  setup(props, context) {
    let locale = zhCn;
    return { locale };
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 9,
      total: 0, //总数
      loading: false, //加载圈
      tablesHight: 600,
      dataList: [],
      name: "",
      version: "",
      minCompatibleVer: "",
      clientType: "",
      publishWay: "",
      updatePolicy: "",
      updateContent: "",
      publishTime: "",
      url: "",
      windowHeight: 200,
    };
  },
  components: {},
  mounted() {
    let user = this.$route.query.uid;
    let token = this.$route.query.token;
    var index = Storage.getItem(Global.homeIndex);
    if (index != null) this.activeIndex = index;
    if (user != null && user.length > 0 && token != null && token.length > 0) {
      Storage.setItem(Global.token, token);
      Storage.setItem(Global.uid, user);
    }
    this.total = this.dataList.length;
    this.getAppFileList();
  },
  created() {
    window.addEventListener("resize", this.getHeight); // 注册监听器
    this.getHeight(); // 页面创建时先调用一次
  },
  unmounted() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    getHeight() {
      //获取数据表的最大高度
      this.tablesHight = window.innerHeight - 265;
      this.windowHeight = window.innerHeight;
    },
    //获取固件列表
    getAppFileList() {
      this.loading = true;
      RequestManager.xzRequest
        .get(RequestApi.appFileList, {
          params: {
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (res.data != null) {
              window.console.log(res.data);
              this.total = res.data.total;
              if (res.data.list != null && res.data.list.length > 0) {
                this.dataList = res.data.list;
              } else {
                this.dataList = [];
              }
            } else {
              this.dataList = [];
              this.total = 0;
            }
          } else {
            ElMessage.error(res.msg);
            this.dataList = [];
            this.total = 0;
          }
          this.currentPage = 1;
          this.loading = false;
        });
    },

    //激活/生效APP包
    appFileActive(id, value) {
      this.loadingUpload = true;
      RequestManager.xzRequest
        .post(RequestApi.appFileActive + "?id=" + id + "&active=" + value, {})
        .then((res) => {
          if (res.state == 0) {
            this.getAppFileList()
          } else {
            ElMessage.error(res.msg);
            this.loadingUpload = false;
          }
        });
    },

    //删除APP包
    appFileDelete(id) {
      this.loadingUpload = true;
      RequestManager.xzRequest
        .post(RequestApi.appFileDelete + "?id=" + id, {})
        .then((res) => {
          if (res.state == 0) {
            ElMessage.success("删除成功");
            this.getAppFileList()
          } else {
            ElMessage.error(res.msg);
            this.loadingUpload = false;
          }
        });
    },

    //确认生效提示
    showTakeEffectDialog(enable, id) {
      var message = ""
      var value = false
      if (enable) {
        message = "确认将该条记录失效？"
        value = false
      } else {
        message = "确认立即发布？"
        value = true
      }
      ElMessageBox.confirm(
        message,
        '温馨提示',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          this.appFileActive(id, value)
        })
        .catch(() => {

        })
    },

    //确认删除提示
    showDeleteDialog(enable, id) {
      if (enable) {
        ElMessage.warning("该条记录已生效，禁止删除")
        return
      }
      ElMessageBox.confirm(
        "删除后将无法恢复，确认删除该条记录？",
        '温馨提示',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          this.appFileDelete(id)
        })
        .catch(() => {

        })
    },

    //获取固件大小
    getFirmwareSize(size) {
      if (size < (1024)) { //小于1KB
        return size + "B"
      } else if (size < (1024 * 1024)) { //小于1MB
        return Math.floor(size / 1024 * 100) / 100 + "KB"
      } else {
        return Math.floor(size / 1024 / 1024 * 100) / 100 + "MB"
      }
    },

    //获取系统类型
    getPlatform(clientType) {
      if (clientType == "1") {
        return "IOS"
      } else if (clientType == "2") {
        return "Android"
      } else {
        return "未知"
      }
    },

    //获取分发平台 1-appstore 2-云端分发 3-testflight
    getPublishWay(publishWay) {
      if (publishWay == "1") {
        return "appstore"
      } else if (publishWay == "2") {
        return "云端分发"
      } else if (publishWay == "3") {
        return "testflight"
      } else {
        return "未知"
      }
    },

    //获取更新策略 0-无需更新 1-弱提示升级 2-强提示升级 3-强制升级 4-无提示升级
    getUpdatePolicy(updatePolicy) {
      if (updatePolicy == "0") {
        return "无需更新"
      } else if (updatePolicy == "1") {
        return "弱提示升级"
      } else if (updatePolicy == "2") {
        return "强提示升级"
      } else if (updatePolicy == "3") {
        return "强制升级"
      } else if (updatePolicy == "4") {
        return "无提示升级"
      } else {
        return "未知"
      }
    },

    //切换页码
    handleChange(pageNum) {
      this.currentPage = pageNum;
    },
    //点击刷新按钮
    refreshList() {
      this.currentPage = 1;
      this.getAppFileList();
    },
    //复制
    copyDownloadUrl(textToCopy) {
      // 创建一个临时的 textarea 元素。
      const el = document.createElement('textarea');
      // 将需要复制的文本内容赋值给 textarea 元素。
      el.value = textToCopy;
      //将 textarea 元素添加到页面中。
      document.body.appendChild(el);
      //选中 textarea 元素中的文本。
      el.select();
      // 执行复制命令，将选中的文本复制到剪贴板
      document.execCommand('copy');
      //从页面中移除 textarea 元素。
      document.body.removeChild(el);
      //弹出提示框，告知用户文本已复制成功。
      ElMessage.success("已复制");
    },
    //下载文件，不直接跳转新窗口打开文件
    download(url, params, filename) {
      window.open(url, '_self');
    },
    //时间处理
    timeDeal(time) {
      return time.replace(new RegExp('T', 'g'), " ")
    }
  },
};
</script>
<style scoped lang="scss">
@import ".././assets/scss/base.scss";
@import ".././assets/css/common.css";

body {
  width: 100%;
  height: 100%;
}

.parent {
  width: calc(100% - 10px);
  margin-left: 5px;
  height: 100%;
  background-color: $colorC;
  display: flex;
  flex-direction: column;
  align-items: center;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
  overflow-y: hidden;

  .tabview {
    width: 100%;
    height: 30px;
    margin-top: 5px;
    background: white;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: auto;

    .input_view {
      margin-left: 5px;
      height: 20px;
      font-size: 10px;
      width: 100px;
    }

    .bt_view {
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
      height: 20px;
      line-height: 20px;
      border-radius: 2px;
      color: #ffffff;
      background-color: $colorA;
      font-size: 10px;
      cursor: pointer;
    }

    .bt_view:hover {
      background-color: $colorB;
    }

    .date_time_str {
      font-size: 10px;
      margin-left: 5px;
      margin-right: 5px;
    }

  }

  .operate_text {
    color: $colorA;
    cursor: pointer;
    background-color: $colorE;
    font-size: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 2px;
  }

  .operate_text_one {
    width: 250px;
    color: $colorA;
    cursor: pointer;
  }

  .pagination {
    width: 98%;
    text-align: right;
    margin-top: 10px;
    font-size: 10px;
    justify-content: flex-end;
  }

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  html {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }

  body::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  body::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  body::-webkit-scrollbar-track {
    background-color: transparent;
  }

  body {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }

}
</style>
