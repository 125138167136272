<template>
  <!-- 隐私协议-->
  <div class="parent">
    <div class="content">
      <span class="title">长沙熙臻电子科技有限公司用户协议</span>
      <span class="privacy">
        本用户协议系由用户与长沙熙臻电子科技有限公司之间就长沙熙臻电子科技有限公司提供的软件产品和服务所订立的权利义务规范。本用户协议所附各类规则或补充条款，为本用户协议不可分割的一部分，与本用户协议具有同等法律效力。本用户协议是用户接受长沙熙臻电子科技有限公司所提供的产品和服务时适用的通用条款。因此，请您在注册使用前或接受长沙熙臻电子科技有限公司的产品和服务之前，详细地阅读本用户协议的所有内容。用户了解并同意：无论事实上是否认真阅读，只要用户点选“同意”键并完成注册，或安装、登录、实际使用并接受长沙熙臻电子科技有限公司产品和服务即视为用户已完全同意并接受本用户协议及长沙熙臻电子科技有限公司所发布的各项相关规则，并愿受其约束。如果发生纠纷，用户不得以未仔细阅读为由进行抗辩。
        <br><br>
        <sapn class="sub_title">一、 定义</sapn>
        <br>1、"Gerlsair"APP为长沙熙臻电子科技有限公司研发的移动端应用软件（下文简称“APP”）。
        <br>2、用户：指愿意接受或实际上已经接受长沙熙臻电子科技有限公司提供的产品和服务（包括产品测试阶段）的单位或个人。 
        <br><br>
        <sapn class="sub_title">二、知识产权和所有权声明</sapn>
        <br>1、本软件及本软件中包含的商标，均由长沙熙臻电子科技有限公司依法拥有知识产权。
        <br>2、未经长沙熙臻电子科技有限公司事先书面同意，用户不得复制、模仿、使用任何上述商标或商业标识，或实施任何其他可能引起相关公众或消费者混淆的行为。
        <br>3、未经长沙熙臻电子科技有限公司事先书面同意，用户不得擅自使用、修改、反向工程、反向编译或反汇编、转载、链接、复制、公开传播、散布、发行或公开发表APP、本软件和本服务的任何程序或内容，或将本软件（或本软件的任何部分）和本服务的任何程序或内容嵌入其他软件或服务，或将本软件（或本软件的任何部分）和本服务的任何程序或内容进行汇编或建立镜像站点等。
        <br>4、未经长沙熙臻电子科技有限公司事先书面同意，用户不得为任何营利性或非营利性目的自行实施、利用、转让或许可任何第三方实施、利用、转让上述知识产权。如有违反，用户需要承担所有的责任。   
        <br>5、用户在使用APP产品和服务过程中所产生并储存于系统中的任何数据信息（包括但不仅限于帐号信息、设备信息、登陆和操作信息等数据信息），均属于相关程序在运行过程中产生的衍生数据。长沙熙臻电子科技有限公司对上述数据信息拥有所有权。 
        <br>6、用户在按照本用户协议规定正常使用APP的过程中对属于其用户帐号的数据信息享有有限使用权。在不影响用户正常接受服务的情况下，长沙熙臻电子科技有限公司有权决定保留或不保留服务器上的全部或部分数据。
        <br><br>
        <sapn class="sub_title">三、APP账号的注册与使用</sapn>
        <br>(一)、用户注册：
        <br>1、在注册成为或实际成为长沙熙臻电子科技有限公司用户并获得许可（帐号和密码）后，就可享用长沙熙臻电子科技有限公司提供的产品服务。
        <br>2、用户的注册信息应真实、有效、完整，并与在长沙熙臻电子科技有限公司留存的资料一致，否则长沙熙臻电子科技有限公司有权停止向其提供服务。
        <br>(二)、账号的使用
        <br>1、账号仅限用户本人使用，且仅用于绑定在本账号下的用户。
        <br>2、用户应妥善保管其账号、密码及更改个人资料所需的验证信息，并正确、安全地使用其账号及密码。
        <br>3、若用户发现其帐号或密码被他人非法使用或有使用异常的情况的，应及时根据长沙熙臻电子科技有限公司公布的处理方式通知长沙熙臻电子科技有限公司，并有权通知长沙熙臻电子科技有限公司采取措施暂停该帐号的登陆和使用，但用户在申请时应提供与其注册身份信息相一致的个人有效身份信息。
        <br>4、用户可免费下载、安装和使用APP软件。
        <br><br>
        <sapn class="sub_title">四、APP为用户提供的服务内容</sapn>
        <br>1、用户绑定设备后可查看家庭的智能设备运行情况。
        <br>2、用户可根据需要通过APP控制智能设备的运行。
        <br><br>
        <sapn class="sub_title">五、用户个人资料的保护及其使用限制</sapn>
        <br>1、长沙熙臻电子科技有限公司尊重并保护用户在APP注册并留存的个人资料及信息，并采取必要措施保护用户的个人信息资料的安全。非经用户同意，长沙熙臻电子科技有限公司不会主动公开、编辑、披露或透露任何用户信息。
        <br>2、仅于下列情况下，长沙熙臻电子科技有限公司可公开、披露、透露或向第三方提供用户信息，且不承担任何责任：
        <br>（1）根据法律法规规定必须披露的；
        <br>（2）司法部门或其它法律授权的机关依照法定程序要求提供；
        <br>（3）在紧急情况下，为维护用户、其他第三方或公众的权益；
        <br>（4）为维护长沙熙臻电子科技有限公司的合法权益；
        <br>（5）其他需要公开、编辑或透露用户信息的情况。
        <br><br>
        <sapn class="sub_title">六、免责条款</sapn>
        <br>1、长沙熙臻电子科技有限公司不保证APP及相关服务不会出现任何程序瑕疵或BUG。长沙熙臻电子科技有限公司将尽其全力采取现有技术手段尽量避免上述事项的发生，但长沙熙臻电子科技有限公司不保证上述事项将全部避免，并不对由此可能产生的问题承担任何赔偿责任。
        <br>2、长沙熙臻电子科技有限公司对于用户不当使用APP及相关服务或在上述情况下所产生的任何直接、间接、衍生、特殊或隐性的损害或利益损失不承担任何赔偿责任。
        <br>3、用户在使用APP过程中，可能存在第三方非法截取、窃取或取得用户信息。长沙熙臻电子科技有限公司将尽量采取合理防御措施防止上述风险的发生，但长沙熙臻电子科技有限公司不承诺上述风险可以绝对避免，也将不对上述风险的发生承担任何责任。
        <br>4、长沙熙臻电子科技有限公司对于用户使用APP控制不具有所有权的设备所产生的一切损失或纠纷不承担任何责任。
        <br><br>
        <sapn class="sub_title">七、用户协议及相关服务规则的修改</sapn>
        <br>1、长沙熙臻电子科技有限公司有权根据需要，不定时地制定、修改、增删（以下统称“修订”）本用户协议及相关服务规则。对本用户协议及相关服务规则的所有修订，长沙熙臻电子科技有限公司将在网站公告栏、APP或其它长沙熙臻电子科技有限公司认为适当的位置发布，而不另行对用户进行个别通知。所有经修订的用户协议及相关服务规则自公布之日起自动生效，并代替原用户协议及原服务规则。
        <br>2、用户应该在每次登录APP前查询包括长沙熙臻电子科技有限公司官方网站在内的上述位置的相关公告，以了解本用户协议及相关服务规则的变化。
        <br><br>
        <sapn class="sub_title">八、通知和送达</sapn>
        <br>1、长沙熙臻电子科技有限公司向用户发出的通知，采用电子邮件或页面公告的方式；该通知于发送之日视为已送达收件人。
        <br>2、用户向长沙熙臻电子科技有限公司发出通知，采用电子邮件的方式，自长沙熙臻电子科技有限公司收到该邮件之日起生效。
        <br><br>
        <sapn class="sub_title">九、不可抗力</sapn>
        <br>如发生不可抗力事件而造成一方不能履行本用户协议，可免除该方违约责任，由各方承担各自相应的风险，但由于该方未及时书面通知对方或未采取防止损失扩大的措施所造成的损失，由该方承担。
        <br><br>
        <sapn class="sub_title">十、管辖</sapn>
        <br>双方在本协议执行过程中如果有纠纷，应以友好协商的方式解决；如协商不成，任何一方可以将争议提交原告所在地人民法院解决。
        <br><br><br><br>
        <sapn class="company">长沙熙臻电子科技有限公司</sapn>
        <br><br><br><br>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "UserAgreement",
  data() {
    return {
    };
  },
  components: {},
  mounted() {

  },
  created() {
    document.title = '熙臻科技用户协议'; // 设置页面标题
    window.addEventListener("resize", this.getHeight); // 注册监听器
    this.getHeight(); // 页面创建时先调用一次
  },
  unmounted() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    getHeight() {
      //获取数据表的最大高度
      this.tablesHight = window.innerHeight - 150;
      this.windowHeight = window.innerHeight;
    },
  },
};
</script>
<style scoped lang="scss">
@import ".././assets/scss/base.scss";
@import ".././assets/css/common.css";

body {
  width: 100%;
  height: 100%;
}

.parent {
  width: 100%;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    .title {
      margin-top: 15px;
      font-size: 14px;
      color: black;
      font-weight: bold;
    }

    .privacy {
      font-size: 12px;
      margin-top: 15px;
      color: #303030;
      margin-left: 20px;
      margin-right: 20px;
      text-align: left;
    }

    .sub_title {
      font-size: 12px;
      color: #303030;
      font-weight: bold;
    }

    .company {
      font-size: 12px;
      color: #303030;
      font-weight: bold;
    }
  }


  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  html {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }

  body::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  body::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  body::-webkit-scrollbar-track {
    background-color: transparent;
  }

  body {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }
}
</style>