<template>
  <!-- 系统管理-用户管理 -->
  <div class="parent">
    <div class="tabview">
      <input type="text" class="input_view" placeholder="输入用户名搜索" />
      <div class="bt_view">查询</div>
    </div>
    <div class="list_view">
      <div class="item" v-for="(item, index) in dataList" :key="index">
        <span class="list_title">姓名：{{ item.userName }}</span>
        <span class="list_content">账号：{{ item.account }}</span>
        <span class="list_content">角色：{{ item.role }}</span>
        <div class="list_bt">
          <span class="list_bt_detail">编辑</span>
          <span class="list_bt_delete">删除</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonUtil from "../util/commonUtil.js";
import Storage from "../storage/index";
import Global from "../components/ProjectGlobal";
import RequestApi from "../components/RequestApi";
import RequestManager from "../util/request";
export default {
  name: "SysUserManage",
  data() {
    return {
      loading: false, //加载圈
      dataList: [
       
      ],
    };
  },
  components: {},
  mounted() {
    let user = this.$route.query.uid;
    let token = this.$route.query.token;
    var index = Storage.getItem(Global.homeIndex);
    if (index != null) this.activeIndex = index;
    if (user != null && user.length > 0 && token != null && token.length > 0) {
      Storage.setItem(Global.token, token);
      Storage.setItem(Global.uid, user);
    }
  },
  methods: {},
};
</script> 
<style scoped lang="scss">
@import ".././assets/scss/base.scss";
body {
  width: 100%;
  height: 100%;
}

.parent {
  width: calc(100% - 10px);
  margin-left: 5px;
  height: 100%;
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow-y: hidden;

  .tabview {
    width: 100%;
    height: 30px;
    margin-top: 5px;
    background: white;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: row;
    align-items: center;

    .input_view {
      border: 1px #e7e7e7 solid;
      margin-left: 5px;
      height: 15px;
      font-size: 8px;
    }

    .bt_view {
      margin-left: 10px;
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 2px;
      padding-bottom: 2px;
      border-radius: 2px;
      color: #ffffff;
      background-color: $colorA;
      font-size: 10px;
      cursor: pointer;
    }

    .bt_view:hover {
      background-color: $colorB;
    }
  }

  .list_view {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    justify-content: flex-start;

    .item {
      padding: 10px;
      border-radius: 5px;
      margin-right: 10px;
      margin-top: 5px;
      background-color: white;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: left;

      .list_title {
        font-size: 10px;
        color: #333333;
        font-weight: bold;
      }

      .list_content {
        font-size: 10px;
        color: #777777;
        margin-top: 5px;
      }

      .list_bt {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 5px;
      }

      .list_bt_detail {
        font-size: 10px;
        color: $colorA;
        border: $colorA 1px solid;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 4px;
        padding-bottom: 4px;
        border-radius: 4px;
      }

      .list_bt_delete {
        font-size: 10px;
        color: #ff0000;
        border: #ff0000 1px solid;
        border-radius: 4px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 4px;
        padding-bottom: 4px;
        margin-left: 10px;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }
  body::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  body::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  body::-webkit-scrollbar-track {
    background-color: transparent;
  }
  body {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }
}
</style>
<style >
.el-pager li {
  font-size: 10px !important;
  min-width: 20px !important;
  height: 20px !important;
}
.el-pagination button {
  font-size: 10px !important;
  min-width: 20px !important;
  height: 20px !important;
}
.el-table {
  font-size: 10px !important;
}
.el-pager li.is-active {
  background-color: #4354f8 !important;
}
</style>
