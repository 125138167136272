// 屏幕自适应穿透px为rem模板
const baseSize = 20; // 设计稿根字体大小
import commonUtil from ".././util/commonUtil";
export default {
    setRem() {
        // if (
        //     document.documentElement.clientWidth >= 762 &&
        //     document.documentElement.clientWidth <= 2040
        // ) {
        //     const scale = document.documentElement.clientWidth / 1920; // 计算缩放比例
        //     document.documentElement.style.fontSize =
        //         baseSize * Math.min(scale, 2) + "px"; // 设置根字体大小
        //         window.console.log("setRem()======762==2040========>");
        // }
        // if (
        //     document.documentElement.clientWidth >= 300 &&
        //     document.documentElement.clientWidth <= 762
        // ) {
        //     // const scale = (750) / 1920 // 计算缩放比例
        //     document.documentElement.style.fontSize = 62.5 + "%"; // 设置根字体大小
        //     // document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
        //     window.console.log("setRem()=======300==762=======>");
        // }
        var isMobile = commonUtil.isMobile()
        window.console.log("isMobile:" + isMobile)
        if (isMobile != null) {
            // document.documentElement.style.fontSize
        } else {
            // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
            var scale = document.documentElement.clientWidth / 1920
            window.console.log("oringin-scale:" + scale)
            // 设置页面根节点字体大小 指最高放大比例为1.5,最小为0.6，可根据实际业务需求调整）
            if (scale < 0.6) {
                scale = 0.6
            } else if (scale > 1.5) {
                scale = 1.5
            }
            window.console.log("new-scale:" + scale)
            document.documentElement.style.fontSize = baseSize * scale + 'px';
        }
    }
}
