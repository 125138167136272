<template>
  <!-- Matter OTA固件列表 -->
  <div class="parent">
    <div class="tabview">
      <el-input class="input_view" clearable placeholder="输入厂家ID" v-model="vendorId" size="small" />
      <div class="bt_view" @click="refreshList">查询</div>
    </div>
    <el-table v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgba(255, 255, 255, 0.9)"
      :data="dataList.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        " style="width: 100%" :max-height="tablesHight" align="center" size="small" :header-cell-style="{
          background: '#f9f9f9',
          color: '#333333',
        }">
      <el-table-column prop="otaName" label="名称" align="center" :width="160"></el-table-column>
      <el-table-column prop="vendorId" label="厂商ID" align="center" :width="90"></el-table-column>
      <el-table-column prop="productId" label="产品ID" align="center" :width="90"></el-table-column>
      <el-table-column prop="softVer" label="软件版本" align="center" :width="90"></el-table-column>
      <el-table-column prop="softVerStr" label="软件版本" align="center" :width="90"></el-table-column>
      <el-table-column label="更新内容" align="center">
        <template #default="scope">
          <span style="white-space: pre-wrap">{{ scope.row.updateContent }} </span>
        </template>
      </el-table-column>
      <el-table-column label="时间" align="center" :width="160">
        <template #default="scope">
          <span>{{ timeDeal(scope.row.updateTime) }} </span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center" width="70">
        <template #default="scope">
          <span :style="{ color: scope.row.active ? '#FFA71A' : '#999999' }">{{ scope.row.active ? "已发布" : "未发布" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" :width="310">
        <template #default="scope">
          <a class="operate_text" @click="showTakeEffectDialog(scope.row.active, scope.row.id)">{{
            scope.row.active ? "失效" : "发布" }}</a>
          <a class="operate_text" @click="showDeleteDialog(scope.row.otaName, scope.row.id)"
            style="color: red;background-color: rgba(255, 0, 0, 0.07);margin-left: 15px;">删除</a>
          <a class="operate_text" @click="download(scope.row.otaUrl, '', scope.row.otaName)"
            style="margin-left: 15px;">下载</a>
          <a class="operate_text" @click="copyDownloadUrl(scope.row.otaUrl)" style="margin-left: 15px;">复制链接</a>
        </template>
      </el-table-column>
    </el-table>
    <el-config-provider :locale="locale">
      <el-pagination class="pagination" background layout="total,prev,pager,next" :pageSize="pageSize" :total="total"
        :current-page="currentPage" @current-change="handleChange"></el-pagination>
    </el-config-provider>
  </div>
</template>
<script>
import { ElTable, ElPagination, ElButton, ElMessage, ElMessageBox, ElInput, ElDatePicker, ElConfigProvider } from "element-plus";
import commonUtil from "../util/commonUtil.js";
import Storage from "../storage/index";
import zhCn from "element-plus/lib/locale/lang/zh-cn"
import Global from "../components/ProjectGlobal";
import RequestApi from "../components/RequestApi";
import RequestManager from "../util/request.js";
import { el, fa, th, tr } from "element-plus/es/locales.mjs";
import { Message } from "@element-plus/icons-vue";
export default {
  name: "MatterOtaFileRecords",
  setup(props, context) {
    let locale = zhCn;
    return { locale };
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 9,
      vendorId: "", //厂商ID 5493
      total: 0, //总数
      loading: false, //加载圈
      tablesHight: 600,
      dataList: [],
      windowHeight: 200,
    };
  },
  components: {},
  mounted() {
    let user = this.$route.query.uid;
    let token = this.$route.query.token;
    var index = Storage.getItem(Global.homeIndex);
    if (index != null) this.activeIndex = index;
    if (user != null && user.length > 0 && token != null && token.length > 0) {
      Storage.setItem(Global.token, token);
      Storage.setItem(Global.uid, user);
    }
    this.total = this.dataList.length;
    this.getOtaFileList();
  },
  created() {
    window.addEventListener("resize", this.getHeight); // 注册监听器
    this.getHeight(); // 页面创建时先调用一次
  },
  unmounted() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    getHeight() {
      //获取数据表的最大高度
      this.tablesHight = window.innerHeight - 265;
      this.windowHeight = window.innerHeight;
    },
    //获取固件列表
    getOtaFileList() {
      this.loading = true;
      RequestManager.xzRequest
        .get(RequestApi.otaFileList, {
          params: {
            vendorId: this.vendorId
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (res.data != null) {
              window.console.log(res.data);
              this.total = res.data.total;
              if (res.data.list != null && res.data.list.length > 0) {
                this.dataList = res.data.list;
              } else {
                this.dataList = [];
              }
            } else {
              this.dataList = [];
              this.total = 0;
            }
          } else {
            ElMessage.error(res.msg);
            this.dataList = [];
            this.total = 0;
          }
          this.currentPage = 1;
          this.loading = false;
        });
    },

    //激活/生效APP包
    otaFileActive(id, value) {
      this.loadingUpload = true;
      var jsonStr = JSON.stringify({
        id: id,
        active: value,
      })
      RequestManager.xzRequest
        .post(RequestApi.otaFileActive, jsonStr)
        .then((res) => {
          if (res.state == 0) {
            this.getOtaFileList()
          } else {
            ElMessage.error(res.msg);
            this.loadingUpload = false;
          }
        });
    },

    //删除固件
    otaFileDelete(id) {
      this.loading = true;
      RequestManager.xzRequest
        .post(RequestApi.otaFileDelete + "?id=" + id, {})
        .then((res) => {
          if (res.state == 0) {
            this.getOtaFileList()
          } else {
            ElMessage.error(res.msg);
            this.loading = false;
          }
        });
    },

    //确认生效提示
    showTakeEffectDialog(active, id) {
      var message = ""
      var value = false
      if (active) {
        message = "确认将该条记录失效？"
        value = false
      } else {
        message = "确认立即发布？"
        value = true
      }
      ElMessageBox.confirm(
        message,
        '温馨提示',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          this.otaFileActive(id, value)
        })
        .catch(() => {

        })
    },

    //确认删除提示
    showDeleteDialog(otaName, id) {
      ElMessageBox.confirm(
        "确认删除【" + otaName + "】?",
        '温馨提示',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          this.otaFileDelete(id)
        })
        .catch(() => {

        })
    },

    //切换页码
    handleChange(pageNum) {
      this.currentPage = pageNum;
    },
    //点击刷新按钮
    refreshList() {
      this.currentPage = 1;
      this.getOtaFileList();
    },
    //复制
    copyDownloadUrl(textToCopy) {
      // 创建一个临时的 textarea 元素。
      const el = document.createElement('textarea');
      // 将需要复制的文本内容赋值给 textarea 元素。
      el.value = textToCopy;
      //将 textarea 元素添加到页面中。
      document.body.appendChild(el);
      //选中 textarea 元素中的文本。
      el.select();
      // 执行复制命令，将选中的文本复制到剪贴板
      document.execCommand('copy');
      //从页面中移除 textarea 元素。
      document.body.removeChild(el);
      //弹出提示框，告知用户文本已复制成功。
      ElMessage.success("已复制");
    },
    //下载文件，不直接跳转新窗口打开文件
    download(url, params, filename) {
      window.open(url, '_self');
    },
    //时间处理
    timeDeal(time) {
      let newTime = time.replace(new RegExp('T', 'g'), " ")
      return newTime.substring(0, 19)
    }
  },
};
</script>
<style scoped lang="scss">
@import ".././assets/scss/base.scss";
@import ".././assets/css/common.css";

body {
  width: 100%;
  height: 100%;
}

.parent {
  width: calc(100% - 10px);
  margin-left: 5px;
  height: 100%;
  background-color: $colorC;
  display: flex;
  flex-direction: column;
  align-items: center;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
  overflow-y: hidden;

  .tabview {
    width: 100%;
    height: 30px;
    margin-top: 5px;
    background: white;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: auto;

    .input_view {
      margin-left: 5px;
      height: 20px;
      font-size: 10px;
      width: 100px;
    }

    .bt_view {
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
      height: 20px;
      line-height: 20px;
      border-radius: 2px;
      color: #ffffff;
      background-color: $colorA;
      font-size: 10px;
      cursor: pointer;
    }

    .bt_view:hover {
      background-color: $colorB;
    }

    .date_time_str {
      font-size: 10px;
      margin-left: 5px;
      margin-right: 5px;
    }

  }

  .operate_text {
    color: $colorA;
    cursor: pointer;
    background-color: $colorE;
    font-size: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 2px;
  }

  .operate_text_one {
    width: 250px;
    color: $colorA;
    cursor: pointer;
  }

  .pagination {
    width: 98%;
    text-align: right;
    margin-top: 10px;
    font-size: 10px;
    justify-content: flex-end;
  }

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  html {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }

  body::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  body::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  body::-webkit-scrollbar-track {
    background-color: transparent;
  }

  body {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }

}
</style>
