<script type="text/javascript">
const token = "token";
const uid = "uid";
const userIcon = "userIcon";
const currentPath = "currentPath";
const currentTab = "currentTab";
const clickMenuId = "clickMenuId";
const openParentMemuId = "openParentMemuId";
const tabsJson = "tabsJson";
const phoneType = 0; //1-Android 2-Ios
const projectBean = null; //项目信息
const appVersion = "1.0.0"; //APP版本信息
export default {
  token,
  uid,
  userIcon,
  phoneType,
  clickMenuId,
  openParentMemuId,
  currentPath,
  currentTab,
  projectBean,
  appVersion,
  tabsJson,
};
</script>
>