<template>
  <!-- APP包上传 -->
  <div class="parent">
    <div class="content" v-loading="loadingUpload" element-loading-text="上传中，请稍等"
      element-loading-background="rgba(255, 255, 255, 0.9)">
      <div class="item">
        <span class="item_title">系统类型：</span>
        <el-select class="product_type" v-model="clientType" clearable placeholder="请选择平台类型" size="small">
          <el-option v-for="item in clientTypeOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <span class="item_title">分发平台：</span>
        <el-select class="product_type" v-model="publishWay" clearable placeholder="请选择分发平台" size="small">
          <el-option v-for="item in publishWayOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <span class="item_title">更新策略：</span>
        <el-select class="product_type" v-model="updatePolicy" clearable placeholder="请选择更新策略" size="small">
          <el-option v-for="item in updatePolicyOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <span class="item_title">软件更新版本：</span>
        <el-input class="input_view" clearable placeholder="请输入软件更新版本" v-model="version" size="small" />
      </div>
      <div class="item">
        <span class="item_title">最低兼容版本：</span>
        <el-input class="input_view" clearable placeholder="请输入最低兼容版本" v-model="minCompatibleVer" size="small" />
      </div>
      <!-- <div class="item">
        <span class="item_title">发布策略</span>
        <div class="item_time" style="text-align: left;">
          <el-radio-group v-model="publishOption">
            <el-radio value="1" size="large">手动发布</el-radio>
            <el-radio value="2" size="large">定时发布</el-radio>
          </el-radio-group>
        </div>
      </div> -->
      <div class="item" v-show="publishOption == 2">
        <span class="item_title"></span>
        <div class="item_time">
          <el-config-provider :locale="locale">
            <el-date-picker v-model="publishTime" type="datetime" placeholder="选择发布时间" size="small"
              value-format="YYYY-MM-DD HH:mm:ss">
            </el-date-picker>
          </el-config-provider>
        </div>
      </div>
      <div class="item" v-show="clientType == '1'">
        <span class="item_title">发布地址：</span>
        <el-input class="input_view" clearable placeholder="请输入发布地址" v-model="iosUrl" size="small" />
      </div>
      <div class="item">
        <span class="item_title">更新内容：</span>
        <el-input class="input_view" clearable placeholder="请输入更新内容" v-model="updateContent" size="small"
          type="textarea" :rows="2" />
      </div>
      <div class="item_upload" v-show="clientType == '2'">
        <span class="item_title">文件上传：</span>
        <el-upload class="upload_view" ref="upload" :limit="fileLimitNum" drag :action="uploadUrl" :data="parseData"
          :headers="myHeaders" :on-preview="handlePreview" :on-remove="handleRemove" :on-change="fileChange"
          :file-list="fileList" :auto-upload="false" :on-exceed="fileLimitEvent" :on-success="uploadSuccess"
          :on-error="uploadFail" style="text-align: center">
          <div>
            <i class="el-icon-upload"></i>
            <div class="upload_text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="upload__tip">
              支持扩展名apk文件，且不超过150M
            </div>
          </div>
        </el-upload>
      </div>
      <div class="bt_view" @click="uploadClick">提交</div>
    </div>
  </div>
</template>
<script>
import { ElInput, ElSelect, ElOption, ElUpload, ElMessage, ElRadioGroup } from "element-plus";
import commonUtil from "../util/commonUtil.js";
import Storage from "../storage/index";
import zhCn from "element-plus/lib/locale/lang/zh-cn"
import Global from "../components/ProjectGlobal";
import RequestApi from "../components/RequestApi";
import RequestManager from "../util/request.js";
import { tr } from "element-plus/es/locales.mjs";
export default {
  name: "AppPackageUpload",
  setup(props, context) {
    let locale = zhCn;
    return { locale };
  },
  data() {
    return {
      version: '',//软件版本
      clientType: '2',//平台类型 1-ios 2-android
      updateContent: '',//更新内容
      appUrl: '',//软件包链接
      iosUrl: '',//ios软件包链接
      publishTime: '',//发布时间
      publishWay: '',//分发平台 1-appstore 2-云端分发 3-testflight
      updatePolicy: '',//更新策略 1-弱提示升级 2-强提示升级 3-强制升级 4-无提示升级
      minCompatibleVer: '',//最小兼容版本
      loadingUpload: false,
      limitNum: 1, // 上传excell时，同时允许上传的最大数
      fileList: [], // excel文件列表
      fileSelectList: [], // excel选中的文件列表
      myHeaders: {},
      uploadUrl: "",
      parseData: {},
      fileLimitNum: 1, //文件最大个数
      publishOption: 0,//1-手动发布 2-定时发布
      clientTypeOptions: [
        {
          value: "1",
          label: "IOS",
        },
        {
          value: "2",
          label: "Android",
        },
      ],
      publishWayOptions: [
        {
          value: "1",
          label: "appstore",
        },
        {
          value: "2",
          label: "云端分发",
        },
        {
          value: "3",
          label: "testflight",
        },
      ],
      updatePolicyOptions: [
        {
          value: "1",
          label: "弱提示升级",
        },
        {
          value: "2",
          label: "强提示升级",
        },
        {
          value: "3",
          label: "强制升级",
        },
        {
          value: "4",
          label: "无提示升级",
        },
      ],
    };
  },
  components: {},
  mounted() {
    let user = this.$route.query.uid;
    let token = this.$route.query.token;
    if (user != null && user.length > 0 && token != null && token.length > 0) {
      Storage.setItem(Global.token, token);
      Storage.setItem(Global.uid, user);
    }
  },
  methods: {
    //上传固件
    uploadFirmware(file) {
      this.loadingUpload = true;
      var url = RequestApi.uploadAppFile + "?file=v" + this.version + ".apk&version=" + this.version + "&clientType=" + this.clientType;
      let formData = new FormData();
      formData.append("file", file);
      RequestManager.xzRequest
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: progressEvent => {
            // window.console.log(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
            window.console.log(progressEvent.loaded);
            window.console.log(progressEvent.total);
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.addAppFile(res.data.url)
          } else {
            ElMessage.error(res.msg);
            this.loadingUpload = false;
          }
        });
    },

    //新增APP包
    addAppFile(fileUrl) {
      var str = JSON.stringify({
        name: 'gerlsair',
        version: "V" + this.version,
        url: fileUrl,
        publishTime: this.publishTime,
        clientType: this.clientType,
        publishWay: this.publishWay,
        updateContent: this.updateContent,
        updatePolicy: this.updatePolicy,
        minCompatibleVer: "V" + this.minCompatibleVer,
      })
      RequestManager.xzRequest
        .post(RequestApi.addAppFile, str)
        .then((res) => {
          if (res.state == 0) {
            ElMessage.success("上传成功");
            this.version = '';
            this.clientType = '2';
            this.updateContent = '';
            this.appUrl = '';
            this.iosUrl = '';
            this.publishTime = '';
            this.publishWay = '';
            this.updatePolicy = '';
            this.minCompatibleVer = '';
            this.fileSelectList = [];
            this.fileList = [];
          } else {
            ElMessage.error(res.msg);
          }
          this.loadingUpload = false;
        });
    },


    // 文件状态改变时的钩子
    fileChange(file, fileList) {
      if (fileList.length != 0) this.fileSelectList.push(file.raw);
    },
    handleRemove() {
      this.fileSelectList = [];
    },
    handlePreview(file) {
      if (file.response.status) {
        ElMessage.success("此文件导入成功");
      } else {
        ElMessage.warning("此文件导入失败");
      }
    },
    //文件超过上数
    fileLimitEvent() {
      ElMessage.warning("只支持单个文件上传");
    },
    //确认上传
    uploadClick() {
      if (this.clientType.length == 0) {
        ElMessage.warning("请选择系统类型");
        return;
      }
      if (this.publishWay.length == 0) {
        ElMessage.warning("请选择分发平台");
        return;
      }
      if (this.updatePolicy.length == 0) {
        ElMessage.warning("请选择更新策略");
        return;
      }
      if (this.version.length == 0) {
        ElMessage.warning("请填写软件更新版本");
        return;
      }
      var pattern = /^(?:[1-9][0-9]|\d)\.(?:[1-9][0-9]|\d)\.(?:[1-9][0-9]|\d)$/; // 正则表达式，匹配格式为1.2.3的字符串
      var softVerIsValid = pattern.test(this.version);
      if (!softVerIsValid) {
        ElMessage.warning("请输入正确的更新软件版本号，格式为：x.x.x");
        return;
      }

      if (this.minCompatibleVer.length == 0) {
        ElMessage.warning("请填写最低兼容版本");
        return;
      }
      var minSoftVerIsValid = pattern.test(this.minCompatibleVer);
      if (!minSoftVerIsValid) {
        ElMessage.warning("请输入正确的最低兼容版本号，格式为：x.x.x");
        return;
      }

      // if (this.publishOption == 0) {
      //   ElMessage.warning("请选择发布策略");
      //   return;
      // }

      // if (this.publishOption == 2 && this.publishTime.length == 0) {
      //   ElMessage.warning("请选择发布时间");
      //   return;
      // }

      if (this.clientType == "1" && this.iosUrl.length === 0) {
        ElMessage.warning("请填写发布地址");
        return;
      }

      if (this.updateContent.length == 0) {
        ElMessage.warning("请填写更新内容");
        return;
      }

      if (this.clientType == "2") {
        if (this.fileSelectList.length === 0) {
          ElMessage.warning("请上传文件");
          return;
        }
        var file = this.fileSelectList[0];
        var ar = file.name.split(".");
        if (ar == null || ar.length == 0) {
          ElMessage.warning("文件只能是apk格式");
          return;
        }
        const extension = ar[ar.length - 1] === "apk";
        const isLt150M = file.size / 1024 / 1024 < 150;
        if (!extension) {
          ElMessage.warning("文件只能是apk格式");
          return;
        }
        if (!isLt150M) {
          ElMessage.warning("文件大小不能超过150MB");
          return;
        }
      }

      if (this.clientType == "2") {
        if (file != undefined) {
          this.uploadFirmware(file);
        }
      } else {
        this.loadingUpload = true;
        this.addAppFile(this.iosUrl)
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import ".././assets/scss/base.scss";
@import ".././assets/css/common.css";

body {
  width: 100%;
  height: 100%;
}

.parent {
  width: calc(100% - 10px);
  margin-left: 5px;
  height: 100%;
  background-color: $colorC;
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-top: 5px;
    padding-top: 10px;

    .item {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      width: 250px;
      margin-left: 20px;
      align-items: center;

      .item_title {
        width: 110px;
        font-size: 10px;
        color: #333333;
        text-align: left;
      }

      .item_time {
        width: 100%;
      }
    }

    .item_upload {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      width: 250px;
      margin-left: 20px;

      .item_title {
        width: 90px;
        font-size: 10px;
        color: #333333;
        text-align: left;
      }

      .upload_view {
        width: 100%;
        margin-left: 13px;

        .upload_text {
          font-size: 10px;
          color: #aaaaaa;
        }

        .upload__tip {
          font-size: 8px;
          color: #aaaaaa;
          margin-top: 5px;
        }
      }

    }

    .bt_view {
      width: 174px;
      height: 23px;
      line-height: 23px;
      border-radius: 2px;
      margin-left: 96px;
      color: #ffffff;
      background-color: $colorA;
      font-size: 10px;
      margin-bottom: 30px;
      margin-top: 20px;
      cursor: pointer;
    }

    .bt_view:hover {
      background-color: $colorB;
    }

    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    html {
      scrollbar-width: thin;
      scrollbar-color: transparent transparent;
    }

    body::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    body::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    body::-webkit-scrollbar-track {
      background-color: transparent;
    }

    body {
      scrollbar-width: thin;
      scrollbar-color: transparent transparent;
    }
  }
}
</style>
<style>
/* 此处禁止改动，会影响到所有使用时间选择器的地方 */
.el-date-editor.el-input,
.el-date-editor.el-input__wrapper {
  width: 183px !important;
  height: 20px !important;
}
</style>
