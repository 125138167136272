let buildType;
switch (process.env.NODE_ENV) {
    case 'development':
        buildType = 'development';
        break;
    case 'test':
        buildType = 'test';
        break;
    case 'production':
        buildType = 'production';
        break;
}

export default {
    buildType
}