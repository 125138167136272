<template>
  <router-view />
</template>
<script>
import Global from "./components/ProjectGlobal";
import CommonUtil from "./util/commonUtil";
export default {
  name: "app",
  components: {},
  data() {
    return {};
  },
  mounted() {
    var isAndroid = CommonUtil.isAndroid();
    var isIos = CommonUtil.isIos();
    if (isAndroid) {
      Global.phoneType = 1;
    } else if (isIos) {
      Global.phoneType = 2;
    }
    window.console.log("phoneType:" + Global.phoneType);
  },
  methods: {},
};
</script>

<style lang="scss">
html,
body,
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  border: 0px;
}
</style>
