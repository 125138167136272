<template>
  <!-- 试剂管理——试剂采购 -->
  <div class="parent">
    <div class="tabview">
      <el-input class="input_view" clearable placeholder="输入设备名称查询" v-model="deviceName" size="small" />
      <el-input class="input_view" clearable placeholder="输入设备ID查询" v-model="deviceId" size="small" />
      <span class="date_time_str">开始时间:</span>
      <el-config-provider :locale="locale">
        <el-date-picker class="date_time" v-model="startTime" type="date" placeholder="选择开始日期" size="small"
          value-format="YYYY-MM-DD">
        </el-date-picker>
        <span class="date_time_str">结束时间:</span>
        <el-date-picker class="date_time" v-model="endTime" type="date" placeholder="选择结束日期" size="small"
          value-format="YYYY-MM-DD">
        </el-date-picker>
      </el-config-provider>
      <div class="bt_view" @click="refreshList">查询</div>
    </div>
    <el-table v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgba(255, 255, 255, 0.9)"
      :data="dataList" style="width: 100%" :max-height="tablesHight" align="center" size="small" :header-cell-style="{
        background: '#f9f9f9',
        color: '#333333',
      }">
      <el-table-column prop="deviceName" label="设备名称" align="center"></el-table-column>
      <el-table-column prop="deviceId" label="设备ID" align="center"></el-table-column>
      <el-table-column prop="model" label="设备型号" align="center"></el-table-column>
      <el-table-column prop="sn" label="设备SN" align="center"></el-table-column>
      <el-table-column prop="batch" label="设备批次" align="center"></el-table-column>
      <el-table-column prop="logDate" label="记录时间" align="center"></el-table-column>
      <el-table-column label="操作" align="center" :width="200">
        <template #default="scope">
          <a class="operate_text" @click="getLogDetial(scope.row.fileUrl, scope.row.fileName)">查看</a>
          <a class="operate_text" @click="download(scope.row.fileUrl, '', scope.row.fileName)"
            style="margin-left: 15px;">下载</a>
        </template>
      </el-table-column>
    </el-table>
    <el-config-provider :locale="locale">
      <el-pagination class="pagination" background layout="total,prev,pager,next" :pageSize="pageSize" :total="total"
        :current-page="currentPage" @current-change="handleChange"></el-pagination>
    </el-config-provider>
    <el-drawer :with-header="false" v-model="isShowLog" direction="rtl" size="50%">
      <div class="crash_log">
        <h1>{{ logTitle }}</h1>
        <p :style='{ height: windowHeight + "px" }' v-html="crashLog"></p>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { ElTable, ElPagination, ElButton, ElMessage, ElInput, ElDatePicker, ElConfigProvider, ElDrawer } from "element-plus";
import commonUtil from "../util/commonUtil.js";
import Storage from "../storage/index";
import zhCn from "element-plus/lib/locale/lang/zh-cn"
import Global from "../components/ProjectGlobal";
import RequestApi from "../components/RequestApi";
import RequestManager from "../util/request";
import { fa, th, tr } from "element-plus/es/locales.mjs";
export default {
  name: "DeviceDebugData",
  setup(props, context) {
    let locale = zhCn;
    return { locale };
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      total: 0, //总数
      loading: false, //加载圈
      tablesHight: 600,
      dataList: [],
      startTime: '',
      endTime: '',
      deviceName: '',
      deviceId: '',
      isShowLog: false,
      logTitle: '',
      crashLog: '',
      windowHeight: 200,
    };
  },
  components: {},
  mounted() {
    let user = this.$route.query.uid;
    let token = this.$route.query.token;
    var index = Storage.getItem(Global.homeIndex);
    if (index != null) this.activeIndex = index;
    if (user != null && user.length > 0 && token != null && token.length > 0) {
      Storage.setItem(Global.token, token);
      Storage.setItem(Global.uid, user);
    }
    this.total = this.dataList.length;
    //开始日期默认为前90天
    const ms = 3 * 30 * 24 * 60 * 60 * 1000;
    this.startTime = commonUtil.getDateStr(new Date().getTime() - ms);
    this.endTime = commonUtil.getDateStr(new Date().getTime());
  },
  created() {
    window.addEventListener("resize", this.getHeight); // 注册监听器
    this.getHeight(); // 页面创建时先调用一次
  },
  activated() {
    let id = this.$route.query.deviceId;
    if (id != null) this.deviceId = id; else this.deviceId = "";
    this.getDeviceDebugData();
  },
  unmounted() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    getHeight() {
      //获取数据表的最大高度
      this.tablesHight = window.innerHeight - 265;
      this.windowHeight = window.innerHeight;
    },
    //获取设备调试数据
    getDeviceDebugData() {
      this.loading = true;
      RequestManager.xzRequest
        .get(RequestApi.deviceDebugData, {
          params: {
            deviceName: this.deviceName,
            deviceId: this.deviceId,
            startDate: this.startTime,
            endDate: this.endTime,
            pageNo: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (res.data != null) {
              this.total = res.data.total;
              if (res.data.list != null && res.data.list.length > 0) {
                this.dataList = res.data.list;
              } else {
                this.dataList = [];
              }
            } else {
              this.dataList = [];
              this.total = 0;
            }
          } else {
            ElMessage.error(res.msg);
            this.dataList = [];
            this.total = 0;
          }
          this.loading = false;
        });
    },

    //切换页码
    handleChange(pageNum) {
      this.currentPage = pageNum;
      this.getDeviceDebugData();
    },
    //点击刷新按钮
    refreshList() {
      this.currentPage = 1;
      this.getDeviceDebugData();
    },
    //下载文件，不直接跳转新窗口打开文件
    download(url, params, filename) {
      return RequestManager.xzRequest.get(url, {
        params: params,
        responseType: 'arraybuffer',
      }).then((r) => {
        const blob = new Blob([r], { type: 'application/vnd.ms-excel' })
        if ('download' in document.createElement('a')) {
          const elink = document.createElement('a');
          elink.download = filename;
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        }
      }).catch((e) => {
        window.console.log(e.message)
      })
    },
    //获取日志详细内容
    getLogDetial(url, title) {
      var that = this;
      RequestManager.xzRequest
        .get(url, {
          params: {},
        })
        .then((res) => {
          var source = JSON.stringify(res).toString().replace("\"", "");
          var str = source.replaceAll("\\n", "<br>");
          that.logTitle = title;
          that.crashLog = str;
          that.isShowLog = true;
        })
    },
  },
};
</script>
<style scoped lang="scss">
@import ".././assets/scss/base.scss";
@import ".././assets/css/common.css";

body {
  width: 100%;
  height: 100%;
}

.parent {
  width: calc(100% - 10px);
  margin-left: 5px;
  height: 100%;
  background-color: $colorC;
  display: flex;
  flex-direction: column;
  align-items: center;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
  overflow-y: hidden;

  .tabview {
    width: 100%;
    height: 30px;
    margin-top: 5px;
    background: white;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: auto;

    .input_view {
      margin-left: 5px;
      height: 20px;
      font-size: 10px;
      width: 140px;
    }

    .bt_view {
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
      height: 20px;
      line-height: 20px;
      border-radius: 2px;
      color: #ffffff;
      background-color: $colorA;
      font-size: 10px;
      cursor: pointer;
    }

    .bt_view:hover {
      background-color: $colorB;
    }

    .date_time_str {
      font-size: 10px;
      margin-left: 5px;
      margin-right: 5px;
    }

  }

  .operate_text {
    color: $colorA;
    cursor: pointer;
    background-color: $colorE;
    font-size: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 2px;
  }

  .pagination {
    width: 98%;
    text-align: right;
    margin-top: 10px;
    font-size: 10px;
    justify-content: flex-end;
  }

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  html {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }

  body::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  body::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  body::-webkit-scrollbar-track {
    background-color: transparent;
  }

  body {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }


  .crash_log {
    color: #000;
    text-align: left;

    h1 {
      font-size: 12px;
    }

    p {
      overflow: scroll;
      font-size: 10px;
      margin-top: 10px;
    }
  }
}
</style>
<style>
/* 此处禁止改动，会影响到所有使用时间选择器的地方 */
.el-date-editor.el-input,
.el-date-editor.el-input__wrapper {
  width: 183px !important;
  height: 20px !important;
}
</style>