<script type="text/javascript">
const client = "app"; //app标识
const deviceDebugData = "v1/device/debug/log/file/list"; //设备调试数据
const uploadFiemware = "v1/ota/firmware/oss/upload"; //上传固件
const fiemwareList = "v1/ota/firmware/upload/list"; //固件列表
const fiemwareDelete = "v1/ota/firmware/delete"; //删除固件
const uploadAppFile = "v1/app/package/upload"; //APP包文件上传
const addAppFile = "v1/app/package/add"; //新增APP包
const appFileList = "v1/app/package/list"; //APP包列表
const appFileDelete = "v1/app/package/delete"; //删除APP包
const appFileActive = "v1/app/package/active"; //激活/生效 APP包
const otaUploadPreSigned = "v1/app/matt/ota/upload/pre/signed"; //获取OTA预签名上传
const otaFileSubmit = "v1/app/matt/ota/submit"; //新增Matter Ota固件
const otaFileList = "v1/app/matt/ota/list"; //获取Matter Ota固件列表
const otaFileActive = "v1/app/matt/ota/modify"; //Matter Ota固件激活
const otaFileDelete = "v1/app/matt/ota/delete"; //Matter Ota固件激活
const deviceList = "v1/device/search/list"; //设备列表
const openDeviceDebug = "v1/device/debug/start/request"; //打开调试
const closeDeviceDebug = "v1/device/debug/stop/request"; //关闭调试
const getDeviceVersionInfo = "v1/web/dev/ota/ver/info"; //获取设备固件版本信息（用于一对一升级）
const verUpgradeToSomeOne = "v1/web/dev/ota/target/ver/upgrade"; //升级指定版本（用于一对一升级）


export default {
  client,
  deviceDebugData,
  uploadFiemware,
  fiemwareList,
  fiemwareDelete,
  uploadAppFile,
  addAppFile,
  appFileList,
  appFileDelete,
  appFileActive,
  otaUploadPreSigned,
  otaFileSubmit,
  otaFileList,
  otaFileActive,
  deviceList,
  openDeviceDebug,
  closeDeviceDebug,
  otaFileDelete,
  getDeviceVersionInfo,
  verUpgradeToSomeOne,
};
</script>