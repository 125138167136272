<template>
  <!-- 固件管理——固件更新 -->
  <div class="parent">
    <div class="content" v-loading="loadingUpload" element-loading-text="上传中，请稍等"
      element-loading-background="rgba(255, 255, 255, 0.9)">
      <div class="item">
        <span class="item_title">设备类型：</span>
        <el-select class="product_type" v-model="model" clearable placeholder="请选择设备类型" size="small">
          <el-option v-for="item in productOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <span class="item_title">硬件版本：</span>
        <el-input class="input_view" clearable placeholder="请输入硬件版本" v-model="hardVer" size="small" />
      </div>
      <div class="item">
        <span class="item_title">软件版本：</span>
        <el-input class="input_view" clearable placeholder="请输入软件版本" v-model="softVer" size="small" />
      </div>
      <div class="item">
        <span class="item_title">更新内容：</span>
        <el-input class="input_view" clearable placeholder="请输入更新内容" v-model="description" size="small" />
      </div>
      <div class="item_upload">
        <span class="item_title">文件上传：</span>
        <el-upload class="upload_view" ref="upload" :limit="fileLimitNum" drag :action="uploadUrl" :data="parseData"
          :headers="myHeaders" :on-preview="handlePreview" :on-remove="handleRemove" :on-change="fileChange"
          :file-list="fileList" :auto-upload="false" :on-exceed="fileLimitEvent" :on-success="uploadSuccess"
          :on-error="uploadFail" style="text-align: center">
          <div>
            <i class="el-icon-upload"></i>
            <div class="upload_text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="upload__tip">
              支持扩展名bin文件，且不超过50M
            </div>
          </div>
        </el-upload>
      </div>
      <div class="bt_view" @click="uploadClick">提交</div>
    </div>
  </div>
</template>
<script>
import { ElInput, ElSelect, ElOption, ElUpload, ElMessage } from "element-plus";
import commonUtil from "../util/commonUtil.js";
import Storage from "../storage/index";
import Global from "../components/ProjectGlobal";
import RequestApi from "../components/RequestApi";
import RequestManager from "../util/request";
export default {
  name: "FirmwareManagement",
  data() {
    return {
      softVer: '',//软件版本
      hardVer: '',//硬件版本
      model: '',//设备类型
      description: '',//更新内容
      loadingUpload: false,
      limitNum: 1, // 上传excell时，同时允许上传的最大数
      fileList: [], // excel文件列表
      fileSelectList: [], // excel选中的文件列表
      myHeaders: {},
      uploadUrl: "",
      parseData: {},
      fileLimitNum: 1, //文件最大个数
      productOptions: [
        {
          value: "81MtAc01",
          label: "81MtAc01",
        },
        {
          value: "81HkAc01",
          label: "81HkAc01",
        },
        {
          value: "81MiAc01",
          label: "81MiAc01",
        },
        {
          value: "81hk1001",
          label: "81hk1001",
        },
        {
          value: "81ma1",
          label: "81ma1",
        },
        {
          value: "81ma2",
          label: "81ma2",
        },
        {
          value: "zonetouch3",
          label: "zonetouch3",
        },
        {
          value: "RcDkP100",
          label: "RcDkP100",
        },
        {
          value: "RcHiAB00",
          label: "RcHiAB00",
        },
        {
          value: "RcMhXY00",
          label: "RcMhXY00",
        },
        {
          value: "RcDkS200",
          label: "RcDkS200",
        },
        {
          value: "RcHrAC00",
          label: "RcHrAC00",
        },
        {
          value: "RcHrDC00",
          label: "RcHrDC00",
        },
        {
          value: "RcHsAB00",
          label: "RcHsAB00",
        },
        {
          value: "RcHsDu00",
          label: "RcHsDu00",
        },
        {
          value: "RcMd4W0",
          label: "RcMd4W0",
        },
        {
          value: "RcMdX100",
          label: "RcMdX100",
        },
        {
          value: "RcPaR100",
          label: "RcPaR100",
        },
        {
          value: "RcSmNa00",
          label: "RcSmNa00",
        },
        {
          value: "RcToAB00",
          label: "RcToAB00",
        },
      ],
    };
  },
  components: {},
  mounted() {
    let user = this.$route.query.uid;
    let token = this.$route.query.token;
    if (user != null && user.length > 0 && token != null && token.length > 0) {
      Storage.setItem(Global.token, token);
      Storage.setItem(Global.uid, user);
    }
  },
  methods: {
    //上传固件
    uploadFirmware(file) {
      this.loadingUpload = true;
      var url = RequestApi.uploadFiemware + "?softVer=" + this.softVer + "&hardVer=" + this.hardVer + "&model=" + this.model + "&description=" + this.description + "&purpose=1";
      let formData = new FormData();
      formData.append("file", file);
      RequestManager.xzRequest
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            ElMessage.success("上传成功");
            this.$refs.upload.clearFiles();
            this.model = "";
            this.hardVer = "";
            this.softVer = "";
            this.description = "";
            this.fileSelectList = [];
          } else {
            ElMessage.error(res.msg);
          }
          this.loadingUpload = false;
        });
    },
    // 文件状态改变时的钩子
    fileChange(file, fileList) {
      if (fileList.length != 0) this.fileSelectList.push(file.raw);
    },
    handleRemove() {
      this.fileSelectList = [];
    },
    handlePreview(file) {
      if (file.response.status) {
        ElMessage.success("此文件导入成功");
      } else {
        ElMessage.warning("此文件导入失败");
      }
    },
    //文件超过上数
    fileLimitEvent() {
      ElMessage.warning("只支持单个文件上传");
    },
    //确认上传
    uploadClick() {
      var pattern = /^(?:[1-9][0-9]|\d)\.(?:[1-9][0-9]|\d)\.(?:[1-9][0-9]|\d)\.(?:[1-9][0-9]|\d)$/; // 正则表达式，匹配格式为1.2.3.4的字符串
      if (this.model.length == 0) {
        ElMessage.warning("请选择设备类型");
        return;
      }
      if (this.hardVer.length == 0) {
        ElMessage.warning("请填写硬件版本");
        return;
      }

      var hardVerIsValid = pattern.test(this.hardVer);
      if (!hardVerIsValid) {
        ElMessage.warning("请输入正确的硬件版本号，格式为：x.x.x.x");
        return;
      }

      if (this.softVer.length == 0) {
        ElMessage.warning("请填写软件版本");
        return;
      }

      var softVerIsValid = pattern.test(this.softVer);
      if (!softVerIsValid) {
        ElMessage.warning("请输入正确的软件版本号，格式为：x.x.x.x");
        return;
      }

      if (this.description.length == 0) {
        ElMessage.warning("请填写更新内容");
        return;
      }

      if (this.fileSelectList.length === 0) {
        ElMessage.warning("请上传文件");
        return;
      }

      var file = this.fileSelectList[0];
      var ar = file.name.split(".");
      if (ar == null || ar.length == 0) {
        ElMessage.warning("文件只能是bin格式");
        return;
      }
      const extension = ar[ar.length - 1] === "bin";
      const isLt10M = file.size / 1024 / 1024 < 50;
      if (!extension) {
        ElMessage.warning("文件只能是bin格式");
        return;
      }
      if (!isLt10M) {
        ElMessage.warning("文件大小不能超过 50MB");
        return;
      }
      if (file != undefined) {
        this.uploadFirmware(file);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import ".././assets/scss/base.scss";
@import ".././assets/css/common.css";

body {
  width: 100%;
  height: 100%;
}

.parent {
  width: calc(100% - 10px);
  margin-left: 5px;
  height: 100%;
  background-color: $colorC;
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-top: 5px;
    padding-top: 10px;

    .item {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      width: 250px;
      align-items: center;

      .item_title {
        width: 90px;
        font-size: 10px;
        color: #333333;
      }
    }

    .item_upload {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      width: 250px;

      .item_title {
        width: 90px;
        font-size: 10px;
        color: #333333;
      }

      .upload_view {
        width: 100%;

        .upload_text {
          font-size: 10px;
          color: #aaaaaa;
        }

        .upload__tip {
          font-size: 8px;
          color: #aaaaaa;
          margin-top: 5px;
        }
      }

    }

    .bt_view {
      width: 183px;
      height: 23px;
      line-height: 23px;
      border-radius: 2px;
      margin-left: 66px;
      color: #ffffff;
      background-color: $colorA;
      font-size: 10px;
      margin-bottom: 30px;
      margin-top: 20px;
      cursor: pointer;
    }


    .bt_view:hover {
      background-color: $colorB;
    }

    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    html {
      scrollbar-width: thin;
      scrollbar-color: transparent transparent;
    }

    body::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    body::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    body::-webkit-scrollbar-track {
      background-color: transparent;
    }

    body {
      scrollbar-width: thin;
      scrollbar-color: transparent transparent;
    }
  }
}
</style>
