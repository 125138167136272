<template>
  <!-- 试剂管理——试剂采购 -->
  <div class="parent">
    <div class="tabview">
      <el-input class="input_view" clearable placeholder="输入用户账号查询" v-model="accountNo" size="small" />
      <el-input class="input_view" clearable placeholder="输入用户ID查询" v-model="userId" size="small" />
      <div class="bt_view" @click="refreshList">查询</div>
    </div>
    <el-table v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgba(255, 255, 255, 0.9)"
      :data="dataList" style="width: 100%" :max-height="tablesHight" align="center" size="small" :header-cell-style="{
        background: '#f9f9f9',
        color: '#333333',
      }">
      <el-table-column prop="accountNo" label="账号" align="center"></el-table-column>
      <el-table-column prop="nickName" label="昵称" align="center"></el-table-column>
      <el-table-column prop="userId" label="用户ID" align="center"></el-table-column>
      <el-table-column label="头像" align="center">
        <template #default="scope">
          <!-- <img class="user_icon" :src="scope.row.avatarUrl" /> -->
          <el-avatar shape="circle" :size="30" :src="scope.row.avatarUrl" style="margin-top: 6px;" />
        </template>
      </el-table-column>
    </el-table>
    <el-config-provider :locale="locale">
      <el-pagination class="pagination" background layout="total,prev,pager,next" :pageSize="pageSize" :total="total"
        :current-page="currentPage" @current-change="handleChange"></el-pagination>
    </el-config-provider>
  </div>
</template>
<script>
import { ElTable, ElPagination, ElButton, ElMessage, ElInput, ElConfigProvider, ElAvatar, } from "element-plus";
import commonUtil from "../util/commonUtil.js";
import Storage from "../storage/index";
import zhCn from "element-plus/lib/locale/lang/zh-cn"
import Global from "../components/ProjectGlobal";
import RequestApi from "../components/RequestApi";
import RequestManager from "../util/request.js";
import { fa, th, tr } from "element-plus/es/locales.mjs";
export default {
  name: "UserList",
  setup(props, context) {
    let locale = zhCn;
    return { locale };
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      total: 0, //总数
      loading: false, //加载圈
      debugLoading: false, //加载圈
      tablesHight: 600,
      dataList: [{
        accountNo: "18664316802",
        userId: "f62be0f70f0b467b8204ca3ed35c6005",
        nickName: "18664316802",
        avatarUrl: "http://cloud.oss-app-public.xizhenelec.com/test/app/iot-manager/user/avatar-f62be0f70f0b467b8204ca3ed35c6005-1725502953923.jpg",
      }, {
        accountNo: "921269818@qq.com",
        userId: "21325729fd894a12bf0bbd2705283e42",
        nickName: "921269818@qq.com",
        avatarUrl: "http://cloud.oss-app-public.xizhenelec.com/test/app/iot-manager/user/avatar-21325729fd894a12bf0bbd2705283e42-1727427617897.jpg",
      }, {
        accountNo: "15116311908",
        userId: "4564115faf154156321fsafa5afasfa512",
        nickName: "15116311908",
        avatarUrl: "http://cloud.oss-app-public.xizhenelec.com/test/app/iot-manager/user/avatar-f62be0f70f0b467b8204ca3ed35c6005-1727427779869.jpg",
      }, {
        accountNo: "pch0923@163.com",
        userId: "5641132124d123121a1121f12136972",
        nickName: "pch0923@163.com",
        avatarUrl: "http://cloud.oss-app-public.xizhenelec.com/test/app/iot-manager/user/avatar-f62be0f70f0b467b8204ca3ed35c6005-1727427893017.jpg",
      }],
      accountNo: '',
      userId: '',
      windowHeight: 200,
    };
  },
  components: {},
  mounted() {
    let user = this.$route.query.uid;
    let token = this.$route.query.token;
    var index = Storage.getItem(Global.homeIndex);
    this.total = this.dataList.length
    if (index != null) this.activeIndex = index;
    if (user != null && user.length > 0 && token != null && token.length > 0) {
      Storage.setItem(Global.token, token);
      Storage.setItem(Global.uid, user);
    }
  },
  created() {
    window.addEventListener("resize", this.getHeight); // 注册监听器
    this.getHeight(); // 页面创建时先调用一次
  },
  unmounted() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    getHeight() {
      //获取数据表的最大高度
      this.tablesHight = window.innerHeight - 265;
      this.windowHeight = window.innerHeight;
    },
    //切换页码
    handleChange(pageNum) {
      this.currentPage = pageNum;
      this.getDeviceList();
    },
    //点击刷新按钮
    refreshList() {
      this.currentPage = 1;
      this.getDeviceList();
    },
  },
};
</script>
<style scoped lang="scss">
@import ".././assets/scss/base.scss";
@import ".././assets/css/common.css";

body {
  width: 100%;
  height: 100%;
}

.parent {
  width: calc(100% - 10px);
  margin-left: 5px;
  height: 100%;
  background-color: $colorC;
  display: flex;
  flex-direction: column;
  align-items: center;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
  overflow-y: hidden;

  .tabview {
    width: 100%;
    height: 30px;
    margin-top: 5px;
    background: white;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: auto;

    .input_view {
      margin-left: 5px;
      height: 20px;
      font-size: 10px;
      width: 140px;
    }

    .bt_view {
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
      height: 20px;
      line-height: 20px;
      border-radius: 2px;
      color: #ffffff;
      background-color: $colorA;
      font-size: 10px;
      cursor: pointer;
    }

    .bt_view:hover {
      background-color: $colorB;
    }

    .date_time_str {
      font-size: 10px;
      margin-left: 5px;
      margin-right: 5px;
    }

  }

  .user_icon {
    width: 40px;
    height: 40px;
  }

  .operate_text {
    color: $colorA;
    cursor: pointer;
    background-color: $colorE;
    font-size: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 2px;
  }

  .pagination {
    width: 98%;
    text-align: right;
    margin-top: 10px;
    font-size: 10px;
    justify-content: flex-end;
  }

  .debug_view {
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .debug_view_top {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      margin-left: 60px;

      .debug_view_top_title {
        width: 100%;
        font-size: 12px;
        text-align: center;
        color: #000000;
        font-weight: bold;
        padding-top: 15px;
        padding-bottom: 20px;
        margin-left: -60px;
      }

      .debug_view_top_intms {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        margin-top: 10px;
        align-items: center;

        .debug_view_top_input {
          height: 20px;
          font-size: 10px;
          width: 70px;
          margin-right: 5px;
          color: #333333;
        }

        span {
          color: #333333;
          font-size: 10px;
        }
      }

    }

    .debug_view_bottom {
      width: 100%;
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      overflow: hidden;
      font-size: 10px;
      justify-content: flex-end;


      .debug_view_bottom_cancel {
        width: 45px;
        height: 20px;
        line-height: 20px;
        color: #666666;
        margin-right: 15px;
        border: #ddd 1px solid;
        border-radius: 3px;
        text-align: center;
        cursor: pointer;
      }

      .debug_view_bottom_cancel:hover {
        border: $colorE 1px solid;
        color: $colorA;
        background-color: $colorE;
      }

      .debug_view_bottom_sure {
        width: 45px;
        height: 20px;
        line-height: 20px;
        color: white;
        margin-right: 30px;
        border: #ddd 1px solid;
        border-radius: 3px;
        text-align: center;
        background-color: $colorA;
        cursor: pointer;
      }

      .debug_view_bottom_sure:hover {
        background-color: $colorB;
      }
    }

  }

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  html {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }

  body::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  body::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  body::-webkit-scrollbar-track {
    background-color: transparent;
  }

  body {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }


  .crash_log {
    color: #000;
    text-align: left;

    h1 {
      font-size: 12px;
    }

    p {
      overflow: scroll;
      font-size: 10px;
      margin-top: 10px;
    }
  }
}
</style>
<style>
/* 此处禁止改动，会影响到所有使用时间选择器的地方 */
.el-date-editor.el-input,
.el-date-editor.el-input__wrapper {
  width: 183px !important;
  height: 20px !important;
}

.el-radio__input.is-checked .el-radio__inner {
  background: #0658fe !important;
}

.el-radio-group .el-radio.is-checked .el-radio__label {
  color: #0658fe !important;
}
</style>