<template>
  <div class="parent">
    <div class="tap_option">
      <el-button
        class="btn_tab"
        type="primary"
        icon="el-icon-refresh-right"
        size="small"
        plain
        @click="findAllMenu"
      >
        查询</el-button
      >
      <el-button
        class="btn_tab"
        type="primary"
        icon="el-icon-printer"
        size="small"
        plain
        @click="showDrawer(null)"
      >
        新增</el-button
      >
    </div>

    <el-table
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-background="rgba(255, 255, 255, 0.9)"
      :data="dataList"
      border
      stripe
      default-expand-all
      row-key="menuId"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      style="width: 100%"
      :max-height="tablesHight"
      align="center"
      size="medium"
      :header-cell-style="{
        background: '#f9f9f9',
        color: '#666666',
        fontSize: '14px',
      }"
      ref="multipleTable"
    >
      <el-table-column
        prop="menuId"
        label="菜单ID"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="menuName"
        label="菜单名称"
        align="center"
      ></el-table-column>
      <el-table-column prop="menuUrl" label="菜单链接 " align="center">
      </el-table-column>
      <el-table-column prop="menuIcon" label="菜单图标 " align="center">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <a class="edit" @click="showDrawer(scope.row)">编辑</a>
          <a
            class="delete"
            @click="deleteMenuDialog(scope.row)"
            v-if="!scope.row.children"
            >删除</a
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      background
      layout="total,prev,pager,next"
      :pageSize="pageSize"
      :total="total"
      :current-page="currentPage"
      @current-change="handleChange"
    ></el-pagination>
    <el-drawer
      title=""
      v-model="drawerVisible"
      :with-header="false"
      direction="rtl"
      size="25%"
    >
      <div class="drawer_view" v-loading="drawerLoading">
        <div class="drawer_top">
          <span class="drawer_title">{{ drawerTitle }}</span>
          <div class="drawer_top_right">
            <span @click="drawerVisible = false">取消</span>
            <span @click="commit">提交</span>
          </div>
        </div>
        <div class="drawer_item" v-if="optionType == 1">
          <span>菜单ID：</span>
          <el-input
            class="drawer_item_input"
            v-model="menuId"
            size="small"
            :disabled="menuIdDisable"
          ></el-input>
        </div>
        <div class="drawer_item">
          <span>菜单名：</span>
          <el-input
            class="drawer_item_input"
            v-model="menuName"
            size="small"
          ></el-input>
        </div>
        <div class="drawer_item">
          <span>菜单链接：</span>
          <el-input
            class="drawer_item_input"
            v-model="menuUrl"
            size="small"
          ></el-input>
        </div>
        <div class="drawer_item">
          <span>菜单图标：</span>
          <el-input
            class="drawer_item_input"
            v-model="menuIcon"
            size="small"
          ></el-input>
        </div>
        <div class="drawer_item">
          <span>父级菜单：</span>
          <el-select
            v-model="parentMenuId"
            placeholder="请选择"
            class="drawer_item_input"
          >
            <el-option
              v-for="item in parentMenuoptions"
              :key="item.menuId"
              :label="item.menuName + '（' + item.menuId + '）'"
              :value="item.menuId"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { ElTable, ElTableColumn, ElDrawer ,ElInput,ElSelect,ElOption,ElPagination,ElMessage,ElMessageBox} from "element-plus";
import Global from "./../../components/ProjectGlobal";
import Storage from "../../storage/index";
import RequestApi from "./../../components/RequestApi";
// import Utils from "../../util/utils";
import RequestManager from "../../util/request";
export default {
  name: "MenuManagement",
  data() {
    return {
      drawerLoading: false, //加载圈
      drawerVisible: false, //是否显示侧边弹框
      currentPage: 1,
      pageSize: 1000,
      total: 0, //总数
      loading: false, //加载圈
      dataList: [],
      parentMenuoptions: [],
      tablesHight: 600,
      drawerTitle: "",
      optionType: 0, //0-新增 1-编辑
      menuId: "",
      menuName: "",
      menuUrl: "",
      menuIcon: "",
      parentMenuId: "",
      menuIdDisable: false,
    };
  },
  components: {
    ElTable,
    ElTableColumn,
    ElDrawer,ElInput,ElSelect,ElOption,ElPagination
  },
  created() {
    window.addEventListener("resize", this.getHeight); // 注册监听器
    this.getHeight(); // 页面创建时先调用一次
  },
  unmounted() {
    window.removeEventListener("resize", this.getHeight);
  },
  mounted() {
    this.controlCrumb();
    this.findAllMenu();
  },
  methods: {
    //查询所有菜单
    findAllMenu() {
      this.loading = true;
      RequestManager.hxCloudRequest
        .post(RequestApi.findAllMenu, {
          user: Storage.getItem(Global.uid),
          token: Storage.getItem(Global.token),
        })
        .then((res) => {
          var tempList = [];
          this.parentMenuoptions = [
            {
              menuId: -1,
              menuName: "无",
            },
          ];
          if (res.code == 200) {
            tempList = res.data;
            var parentList = [];
            if (tempList != null && tempList.length > 0) {
              for (var i = 0; i < tempList.length; i++) {
                if (tempList[i].parentMenuId == -1) {
                  parentList.push(tempList[i]);
                  this.parentMenuoptions.push(tempList[i]);
                }
              }
              for (var k = 0; k < tempList.length; k++) {
                for (var j = 0; j < parentList.length; j++) {
                  if (tempList[k].parentMenuId == parentList[j].menuId) {
                    if (parentList[j]["children"] == null) {
                      parentList[j]["children"] = [tempList[k]];
                    } else {
                      parentList[j].children.push(tempList[k]);
                    }
                  }
                }
              }
              this.dataList = parentList;
            } else {
              this.dataList = [];
            }
          } else {
            this.dataList = [];
            ElMessage.error(res.message);
          }
          this.total = tempList.length;
          this.loading = false;
        });
    },
    //删除角色
    deleteMenu(menuId) {
      RequestManager.hxCloudRequest
        .post(RequestApi.deleteMenu, {
          user: Storage.getItem(Global.uid),
          token: Storage.getItem(Global.token),
          menuId: menuId,
        })
        .then((res) => {
          if (res.code == 200) {
            ElMessage.success("删除成功");
            this.findAllMenu();
          } else {
            ElMessage.error(res.message);
          }
        });
    },
    //编辑或增加菜单
    commit() {
      if (this.menuName == "") {
        ElMessage.warning("请输入菜单名称");
        return;
      }
      if (this.menuUrl == "") {
        ElMessage.warning("请输入菜单链接");
        return;
      }
      if (this.menuIcon == "") {
        ElMessage.warning("请输入菜单图标");
        return;
      }
      this.drawerLoading = true;
      RequestManager.hxCloudRequest
        .post(RequestApi.addOrEditMenu, {
          user: Storage.getItem(Global.uid),
          token: Storage.getItem(Global.token),
          menuId: this.menuId,
          menuName: this.menuName,
          menuUrl: this.menuUrl,
          menuIcon: this.menuIcon,
          parentMenuId: this.parentMenuId,
        })
        .then((res) => {
          if (res.code == 200) {
            ElMessage.success("提交成功");
            this.findAllMenu();
            this.drawerVisible = false;
          } else {
            ElMessage.error(res.message);
          }
          this.drawerLoading = false;
        });
    },
    //显示侧边弹框
    showDrawer(bean) {
      this.menuId = "";
      this.menuName = "";
      this.menuUrl = "";
      this.menuIcon = "";
      this.parentMenuId = "";
      if (bean != null) {
        this.optionType = 1;
        this.drawerTitle = "编辑菜单";
        this.menuId = bean.menuId;
        this.menuName = bean.menuName;
        this.menuUrl = bean.menuUrl;
        this.menuIcon = bean.menuIcon;
        this.parentMenuId = bean.parentMenuId;
        this.menuIdDisable = true;
      } else {
        this.optionType = 0;
        this.drawerTitle = "新增菜单";
        this.menuIdDisable = false;
      }
      this.drawerVisible = true;
    },
    //删除菜单
    deleteMenuDialog(item) {
      ElMessageBox.confirm(
        "确认删除'" + item.menuName + "' 菜单? 将同步删除角色关联的该菜单数据",
        "提示",
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          customClass: "custom-box",
        }
      )
        .then(() => {
          this.deleteMenu(item.menuId);
        })
        .catch(() => {});
    },
    //设置面包屑文案
    controlCrumb() {
      this.$emit("controlCrumb", "首页", "权限管理", "菜单管理");
    },
    getHeight() {
      // 获取数据表的最大高度
      this.tablesHight = window.innerHeight - 265;
      window.console.log("tablesHight" + this.tablesHight);
    },
    //切换页码
    handleChange(pageNum) {
      this.currentPage = pageNum;
      this.getAllWorkOrderList();
    },
    //点击刷新按钮
    refreshList(e) {
      let target = e.target;
      // 根据button组件内容 里面包括一个span标签，如果设置icon，则还包括一个i标签，其他情况请自行观察。
      // 所以，在我们点击到button组件上的文字也就是span标签上时，直接执行e.target.blur()不会生效，所以要加一层判断。
      if (target.nodeName == "SPAN" || target.nodeName == "I") {
        target = e.target.parentNode;
      }
      target.blur();
      this.currentPage = 1;
      this.getAllWorkOrderList();
    },
    //跳转至首页
    navToHome() {
      window.location.href = "http://htopcloud.chinaoak.cn:8080/summary.aspx";
    },
  },
};
</script>
<style  lang="scss" scoped>
body {
  margin: 0;
}

.parent {
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-top: 10px;
  overflow: hidden;

  .tab_page {
    background-color: white;
    padding-left: 10px;
  }

  .tap_option {
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    overflow: auto;
  }

  .pagination {
    text-align: right;
    margin-top: 10px;
  }

  ::v-deep {
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #23c2f7;
    }
  }

  .edit {
    color: #02ace5;
    font-size: 14px;
    margin-right: 20px;
    cursor: pointer;
  }

  .edit:hover {
    color: #0296c7;
  }

  .delete {
    color: #e50202;
    font-size: 14px;
    margin-right: 20px;
    cursor: pointer;
  }

  .delete:hover {
    color: #bd0101;
  }

  .el-button--primary {
    color: #02ade5 !important;
    background-color: #ffffff;
    border-color: #02ade5;

    &:hover {
      background-color: #02ace5;
      color: white !important;
    }
  }

  .drawer_view {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .drawer_top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      margin-top: 10px;
      overflow: hidden;

      .drawer_title {
        color: #333333;
        font-weight: bold;
        font-size: 15px;
        margin-left: 10px;
        overflow: hidden;
      }

      .drawer_top_right {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        align-items: center;
        margin-right: 10px;

        span {
          color: white;
          background-color: #00a4db;
          border-radius: 4px;
          padding-top: 5px;
          padding-bottom: 5px;
          padding-left: 16px;
          padding-right: 16px;
          cursor: pointer;
          margin-left: 10px;
        }
        span:hover {
          background-color: #0097c9;
        }
      }
    }

    .drawer_item {
      width: 100%;
      display: flex;
      flex-direction: row;
      overflow: hidden;
      align-items: center;
      margin-top: 10px;

      span {
        color: #333333;
        font-size: 13px;
        margin-left: 10px;
        width: 70px;
      }

      .drawer_item_input {
        flex: 1;
        margin-right: 10px;
      }
    }
  }
}
</style>
<style>
/* el-tab 样式修改 */
.el-tabs__item.is-active {
  color: #02ace5;
}

.el-tabs__item:hover {
  color: #02ace5;
}

.el-tabs__active-bar {
  background-color: #02ace5;
}
</style>