<template>
  <!-- 试剂管理——试剂采购 -->
  <div class="parent">
    <div class="tabview">
      <el-input class="input_view" clearable placeholder="输入设备名称查询" v-model="name" size="small" />
      <el-input class="input_view" clearable placeholder="输入设备ID查询" v-model="deviceId" size="small" />
      <el-input class="input_view" clearable placeholder="输入设备型号查询" v-model="model" size="small" />
      <el-input class="input_view" clearable placeholder="输入设备SN查询" v-model="sn" size="small" />
      <div class="bt_view" @click="refreshList">查询</div>
      <div class="bt_view" @click="isShowAddNewDeviceView = true">新增设备</div>
      <div class="bt_view" @click="isShowAddNewDeviceView = true">批量新增设备</div>
    </div>
    <el-table v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgba(255, 255, 255, 0.9)"
      :data="dataList" style="width: 100%" :max-height="tablesHight" align="center" size="small" :header-cell-style="{
        background: '#f9f9f9',
        color: '#333333',
      }">
      <el-table-column prop="deviceName" label="设备名称" align="center"></el-table-column>
      <el-table-column prop="deviceId" label="设备ID" align="center"></el-table-column>
      <el-table-column prop="model" label="设备型号" align="center"></el-table-column>
      <el-table-column prop="serialNum" label="设备SN" align="center"></el-table-column>
      <el-table-column label="创建时间" align="center" :width="200">
        <template #default="scope">
          <span>{{ timeDeal(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="MQTT状态" align="center">
        <template #default="scope">
          <span :style="{ color: scope.row.reachable == 1 ? '#67c28a' : '#606266' }">{{ scope.row.reachable ==
            1 ? '在线'
            : '离线' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="调试状态" align="center">
        <template #default="scope">
          <span :style="{ color: scope.row.debugActive == true ? '#67c28a' : '#606266' }">{{ scope.row.debugActive ==
            true ? '开启'
            : '关闭' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" :width="150">
        <template #default="scope">
          <a class="operate_text" @click="showDeviceDetailView(scope.row)">详情</a>
        </template>
      </el-table-column>
    </el-table>
    <el-config-provider :locale="locale">
      <el-pagination class="pagination" background layout="total,prev,pager,next" :pageSize="pageSize" :total="total"
        :current-page="currentPage" @current-change="handleChange"></el-pagination>
    </el-config-provider>
    <CustomModal :showModal="isShowAddNewDeviceView">
      <template v-slot:body>
        <div class="add_device_view">
          <div class="add_device_view_top">
            <span class="add_device_view_top_title">新增设备</span>
            <div class="add_device_view_top_intms">
              <span>设备名称：</span>
              <el-input class="add_device_view_top_input" clearable size="small" v-model="addDeviceName" />
            </div>
            <div class="add_device_view_top_intms">
              <span>设备型号：</span>
              <el-input class="add_device_view_top_input" clearable size="small" v-model="addDeviceModal" />
            </div>
            <div class="add_device_view_top_intms">
              <span>设备ID：</span>
              <el-input class="add_device_view_top_input" clearable size="small" v-model="addDeviceId" />
            </div>
            <div class="add_device_view_top_intms">
              <span>设备SN：</span>
              <el-input class="add_device_view_top_input" clearable size="small" v-model="addDeviceSN" />
            </div>
          </div>
          <div class="add_device_view_bottom">
            <span class="add_device_view_bottom_cancel" @click="cancelAddDeviceView">取消</span>
            <span class="add_device_view_bottom_sure" @click="addNewDevice">确定</span>
          </div>
        </div>
      </template>
    </CustomModal>
    <CustomFullPageModal :showModal="isShowDeviceDetailView">
      <template v-slot:body>
        <div class="detail_view">
          <img class="detail_view_close" src="../assets/image/close.png" alt="" :class="{ 'icon-hover': isHovering }"
            @mouseenter="isHovering = true" @mouseleave="isHovering = false" @click="closeDeviceDetailView" />
          <div class="device_detail_loading" v-loading="deviceDetailLoading"></div>
          <div class="detail_view_content">
            <div class="detail_view_content_title">
              <div class="detail_view_content_title_line"></div>
              <div class="detail_view_content_title_text">设备信息</div>
            </div>
            <div class="detail_view_content_info">
              <div class="detail_view_content_info_h">
                <div class="detail_view_content_info_h_item">
                  设备名称：{{ deviceDetailBean == null ? "" : deviceDetailBean.deviceName }}</div>
                <div class="detail_view_content_info_h_item">设备
                  ID：{{ deviceDetailBean == null ? "" : deviceDetailBean.deviceId }}</div>
                <div class="detail_view_content_info_h_item">设备型号：{{ deviceDetailBean == null ? "" :
                  deviceDetailBean.model }}
                </div>
              </div>
              <div class="detail_view_content_info_h">
                <div class="detail_view_content_info_h_item">设备
                  SN：{{ deviceDetailBean == null ? "" : deviceDetailBean.serialNum }}</div>
                <div class="detail_view_content_info_h_item">
                  MQTT状态：{{ deviceDetailBean == null ? "" : deviceDetailBean.reachable ==
                    1 ? '在线'
                    : '离线' }}</div>
                <div class="detail_view_content_info_h_item">
                  创建时间：{{ deviceDetailBean == null ? "" : timeDeal(deviceDetailBean.createTime) }}</div>
              </div>
            </div>
            <div class="detail_view_content_title">
              <div class="detail_view_content_title_line"></div>
              <div class="detail_view_content_title_text">设备调试</div>
            </div>
            <div class="detail_view_content_debug">
              <div class="debug_view_top_intms">
                <span>数据上传间隔时间：</span>
                <el-input class="debug_view_top_input" clearable v-model="intMs" size="small"
                  oninput="value=value.replace(/[^\d]/g,'')" />
                <span>毫秒</span>
              </div>
              <div class="debug_bt_view"
                @click="debugOperate(1, deviceDetailBean.deviceId, deviceDetailBean.deviceName)">打开调试</div>
              <div class="debug_bt_view"
                @click="debugOperate(0, deviceDetailBean.deviceId, deviceDetailBean.deviceName)">关闭调试</div>
              <div class="debug_bt_view" @click="goDeviceDebugDataPage(deviceDetailBean.deviceId)">查看调试数据</div>
            </div>
            <div class="detail_view_content_title">
              <div class="detail_view_content_title_line"></div>
              <div class="detail_view_content_title_text">设备升级</div>
            </div>
            <div class="detail_view_content_upgrade">
              <div class="detail_view_content_upgrade_item" v-for="(item, index) in deviceVersionInfoList" :key="index">
                <div class="detail_view_content_upgrade_item_one">
                  <div class="detail_view_content_upgrade_item_title">{{ item.pluginFlag ? "子固件" : "主固件" }}</div>
                  <div class="detail_view_content_upgrade_item_text">固件型号：{{ item.pluginFlag ? item.subModel :
                    item.model
                    }}
                  </div>
                  <div class="detail_view_content_upgrade_item_text">当前软件版本：{{ item.currentVer }}</div>
                  <span class="detail_view_content_upgrade_item_option">待升级软件版本：</span>
                  <el-select class="detail_view_content_upgrade_item_option_type" v-model="item.selectVersion" clearable
                    placeholder="请选择待升级版本" size="small" @visible-change="selectShow($event, item)"
                    @change="handleSelectChange($event, item)" @clear="handleClear(item)">
                    <el-option v-for="opt in item.versionOptions" :key="opt.value" :label="opt.label"
                      :value="opt.value">
                    </el-option>
                  </el-select>
                  <div class="upgrade_bt_view" @click="verUpgradeToSomeOne(item)">立即升级</div>
                  <div class="upgrade_bt_view" @click="getDeviceVersionInfo()">查询</div>
                </div>
                <div class="detail_view_content_upgrade_item_content">更新内容：{{ item.desc }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </CustomFullPageModal>
  </div>
</template>
<script>
import { ElTable, ElPagination, ElButton, ElMessage, ElInput, ElDatePicker, ElConfigProvider, ElRadio, ElRadioGroup, ElScrollbar } from "element-plus";
import CustomModal from "../components/CustomModal.vue"
import CustomFullPageModal from "../components/CustomFullPageModal.vue"
import commonUtil from "../util/commonUtil.js";
import Storage from "../storage/index";
import zhCn from "element-plus/lib/locale/lang/zh-cn"
import Global from "../components/ProjectGlobal";
import RequestApi from "../components/RequestApi";
import RequestManager from "../util/request.js";
import { de, fa, th, tr } from "element-plus/es/locales.mjs";
export default {
  name: "DeviceList",
  setup(props, context) {
    let locale = zhCn;
    return { locale };
  },
  data() {
    return {
      isHovering: false,
      currentPage: 1,
      pageSize: 10,
      total: 0, //总数
      loading: false, //加载圈
      tablesHight: 600,
      dataList: [],
      name: '',
      deviceId: '',
      model: '',
      sn: '',
      intMs: 2000,//数据上传间隔时间
      windowHeight: 200,
      isShowAddNewDeviceView: false,
      isShowDeviceDetailView: false,
      deviceDetailLoading: false,
      debugOperation: "1", //0-关闭调试 1-打开调试
      addDeviceName: "",
      addDeviceModal: "",
      addDeviceId: "",
      addDeviceSN: "",
      deviceDetailBean: null,
      deviceVersionInfoList: [],
      versionList: [],//固件列表
      currentSelectDeviceId: "",
    };
  },
  components: { CustomModal, CustomFullPageModal },
  mounted() {
    let user = this.$route.query.uid;
    let token = this.$route.query.token;
    var index = Storage.getItem(Global.homeIndex);
    if (index != null) this.activeIndex = index;
    if (user != null && user.length > 0 && token != null && token.length > 0) {
      Storage.setItem(Global.token, token);
      Storage.setItem(Global.uid, user);
    }
    this.getDeviceList(true);
  },
  created() {
    window.addEventListener("resize", this.getHeight); // 注册监听器
    this.getHeight(); // 页面创建时先调用一次
  },
  unmounted() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    getHeight() {
      //获取数据表的最大高度
      this.tablesHight = window.innerHeight - 265;
      this.windowHeight = window.innerHeight;
    },
    //请求设备列表
    getDeviceList(isNeedShowLoading) {
      if (isNeedShowLoading)
        this.loading = true;
      RequestManager.xzRequest
        .get(RequestApi.deviceList, {
          params: {
            name: this.name,
            deviceId: this.deviceId,
            model: this.model,
            sn: this.sn,
            pageNo: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (res.data != null) {
              this.total = res.data.total;
              if (res.data.list != null && res.data.list.length > 0) {
                this.dataList = res.data.list;
              } else {
                this.dataList = [];
              }
            } else {
              this.dataList = [];
              this.total = 0;
            }
          } else {
            ElMessage.error(res.msg);
            this.dataList = [];
            this.total = 0;
          }
          this.loading = false;
        });
    },
    //请求设备版本信息（用于一对一升级）
    getDeviceVersionInfo() {
      this.deviceDetailLoading = true;
      RequestManager.xzRequest
        .get(RequestApi.getDeviceVersionInfo, {
          params: {
            deviceId: this.currentSelectDeviceId,
          },
        })
        .then((res) => {
          this.deviceVersionInfoList = [];
          if (res.state == 0) {
            if (res.data != null && res.data.length > 0) {
              res.data.forEach(item => {
                item.versionOptions = []
                item.selectVersion = ""
                item.desc = ""
                this.deviceVersionInfoList.push(item);
              })
            } else {
              this.deviceVersionInfoList = [];
            }
          } else {
            ElMessage.error(res.msg);
            this.deviceVersionInfoList = [];
          }
          this.deviceDetailLoading = false;
        });
    },
    //获取固件列表
    getFiemwareList(model, item) {
      item.versionOptions = [];
      var date = new Date();
      var year = date.getFullYear();
      var month = (date.getMonth() + 1) + "";
      var dates = date.getDate() + "";
      var newMonth = month.length == 1 ? ("0" + month) : month;
      var newDate = dates.length == 1 ? ("0" + dates) : dates;
      var endT = " 23:59:59";
      var endTime = year + "-" + newMonth + "-" + newDate + endT;
      this.deviceDetailLoading = true;
      RequestManager.xzRequest
        .get(RequestApi.fiemwareList, {
          params: {
            model: model,
            softVer: "",
            harVer: "",
            startTime: "2024-04-15 00:00:00",
            endTime: endTime,
            pageNo: 1,
            pageSize: 10000,
            purpose: 2,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (res.data != null) {
              if (res.data.list != null && res.data.list.length > 0) {
                res.data.list.forEach(it => {
                  it.value = it.sv,
                    it.label = it.sv,
                    item.versionOptions.push(it)
                })
              } else {
                item.versionOptions = [];
              }
            } else {
              item.versionOptions = [];
            }
          } else {
            ElMessage.error(res.msg);
            item.versionOptions = [];
          }
          this.deviceDetailLoading = false;
        });
    },
    //升级到指定版本
    verUpgradeToSomeOne(item) {
      if (item.selectVersion == "" || item.selectVersion == null) {
        ElMessage.warning("请先选择升级版本");
        return;
      }
      this.deviceDetailLoading = true;
      var requestStr = "";
      if (item.pluginFlag) {
        requestStr = JSON.stringify({
          deviceId: item.deviceId,
          upgradeVer: item.selectVersion,
          subModel: item.subModel,
          sn: item.sn,
        })
      } else {
        requestStr = JSON.stringify({
          deviceId: item.deviceId,
          upgradeVer: item.selectVersion,
        })
      }
      RequestManager.xzRequest
        .post(RequestApi.verUpgradeToSomeOne, requestStr)
        .then((res) => {
          if (res.state == 0) {
            ElMessage.success("升级指令发送成功");
          } else {
            ElMessage.error(res.msg);
          }
          this.deviceDetailLoading = false;
        });
    },
    //下拉获取版本信息
    selectShow(visible, item) {
      if (visible) {
        var model = item.model
        if (item.pluginFlag) model = item.subModel
        this.getFiemwareList(model, item)
      }
    },

    //选择待升级版本
    handleSelectChange(selectVersion, item) {
      item.versionOptions.forEach(it => {
        if (it.sv == selectVersion) {
          item.desc = it.desc
          item.selectVersion = selectVersion
        }
      })
    },

    //清空选择的版本
    handleClear(item) {
      item.desc = ""
    },

    //操作设备调试
    debugOperate(debugOperation, deviceId, deviceName) {
      if (debugOperation == 1) {
        if (this.intMs < 300 || this.intMs > 10000) {
          ElMessage.warning("时间间隔范围为300-10000毫秒，请重新输入。");
          return
        }
        this.openOperate(deviceId, deviceName)
      } else {
        this.closeOperate(deviceId, deviceName)
      }
    },
    //打开调试
    openOperate(deviceId, deviceName) {
      this.deviceDetailLoading = true;
      RequestManager.xzRequest
        .post(RequestApi.openDeviceDebug + "?deviceId=" + deviceId + "&intMs=" + this.intMs, {})
        .then((res) => {
          this.deviceDetailLoading = false;
          if (res.state == 0) {
            ElMessage.success("已为您开启【" + deviceName + "】调试")
            this.getDeviceList(false)
          } else {
            ElMessage.error(res.msg);
          }
        });
    },
    //关闭调试
    closeOperate(deviceId, deviceName) {
      this.deviceDetailLoading = true;
      RequestManager.xzRequest
        .post(RequestApi.closeDeviceDebug + "?deviceId=" + deviceId, {})
        .then((res) => {
          this.deviceDetailLoading = false;
          if (res.state == 0) {
            ElMessage.success("已为您关闭【" + deviceName + "】调试")
            this.getDeviceList(false)
          } else {
            ElMessage.error(res.msg);
          }
        });
    },
    //显示设备详情弹框
    showDeviceDetailView(item) {
      this.isShowDeviceDetailView = true;
      this.deviceVersionInfoList = []
      this.deviceDetailBean = item
      this.currentSelectDeviceId = item.deviceId;
      this.getDeviceVersionInfo()
    },
    //关闭设备详情弹框
    closeDeviceDetailView() {
      this.isShowDeviceDetailView = false;
    },
    //跳转指定设备的调试数据列表
    goDeviceDebugDataPage(deviceId) {
      this.$router.push({ path: '/DeviceDebugData', query: { deviceId: deviceId } });
    },
    //切换页码
    handleChange(pageNum) {
      this.currentPage = pageNum;
      this.getDeviceList(true);
    },
    //点击刷新按钮
    refreshList() {
      this.getDeviceList(true);
    },
    //取消新增设备弹框
    cancelAddDeviceView() {
      this.isShowAddNewDeviceView = false
      this.addDeviceName = ""
      this.addDeviceModal = ""
      this.addDeviceId = ""
      this.addDeviceSN = ""
    },
    //新增设备
    addNewDevice() {
      this.isShowAddNewDeviceView = false
      this.addDeviceName = ""
      this.addDeviceModal = ""
      this.addDeviceId = ""
      this.addDeviceSN = ""
    },
    //时间处理
    timeDeal(time) {
      var t1 = time.replace(new RegExp('T', 'g'), " ")
      return t1.substring(0, t1.lastIndexOf("."));
    }
  },
};
</script>
<style scoped lang="scss">
@import ".././assets/scss/base.scss";
@import ".././assets/css/common.css";

body {
  width: 100%;
  height: 100%;
}

.parent {
  width: calc(100% - 10px);
  margin-left: 5px;
  height: 100%;
  background-color: $colorC;
  display: flex;
  flex-direction: column;
  align-items: center;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
  overflow-y: hidden;

  .tabview {
    width: 100%;
    height: 30px;
    margin-top: 5px;
    background: white;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: auto;

    .input_view {
      margin-left: 5px;
      height: 20px;
      font-size: 10px;
      width: 140px;
    }

    .bt_view {
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
      height: 20px;
      line-height: 20px;
      border-radius: 2px;
      color: #ffffff;
      background-color: $colorA;
      font-size: 10px;
      cursor: pointer;
    }

    .bt_view:hover {
      background-color: $colorB;
    }

    .date_time_str {
      font-size: 10px;
      margin-left: 5px;
      margin-right: 5px;
    }

  }

  .operate_text {
    color: $colorA;
    cursor: pointer;
    background-color: $colorE;
    font-size: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 2px;
  }

  .pagination {
    width: 98%;
    text-align: right;
    margin-top: 10px;
    font-size: 10px;
    justify-content: flex-end;
  }

  .add_device_view {
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .add_device_view_top {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      margin-left: 60px;

      .add_device_view_top_title {
        width: 100%;
        font-size: 12px;
        text-align: center;
        color: #000000;
        font-weight: bold;
        padding-top: 15px;
        padding-bottom: 20px;
        margin-left: -60px;
      }

      .add_device_view_top_intms {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        margin-top: 10px;
        align-items: center;

        .add_device_view_top_input {
          height: 20px;
          font-size: 10px;
          width: 140px;
          margin-right: 5px;
          color: #333333;
        }

        span {
          color: #333333;
          font-size: 10px;
          width: 52px;
          text-align: left;
        }
      }

    }

    .add_device_view_bottom {
      width: 100%;
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      overflow: hidden;
      font-size: 10px;
      justify-content: flex-end;


      .add_device_view_bottom_cancel {
        width: 45px;
        height: 20px;
        line-height: 20px;
        color: #666666;
        margin-right: 15px;
        border: #ddd 1px solid;
        border-radius: 3px;
        text-align: center;
        cursor: pointer;
      }

      .add_device_view_bottom_cancel:hover {
        border: $colorE 1px solid;
        color: $colorA;
        background-color: $colorE;
      }

      .add_device_view_bottom_sure {
        width: 45px;
        height: 20px;
        line-height: 20px;
        color: white;
        margin-right: 30px;
        border: #ddd 1px solid;
        border-radius: 3px;
        text-align: center;
        background-color: $colorA;
        cursor: pointer;
      }

      .add_device_view_bottom_sure:hover {
        background-color: $colorB;
      }
    }

  }

  .detail_view {
    width: 100%;
    height: 500px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .device_detail_loading {
      position: absolute;
      right: 44%;
      bottom: 40%;
      width: 100px;
      height: 30px;
    }

    .detail_view_close {
      position: absolute;
      right: 0;
      width: 20px;
      height: 20px;
      margin-top: 10px;
      margin-right: 10px;
      cursor: pointer;
      transition: transform 0.5s ease;
    }

    .icon-hover {
      transform: rotate(180deg) scale(1.2);
    }

    .detail_view_content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      margin-left: 15px;
      margin-top: 15px;

      .detail_view_content_title {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;

        .detail_view_content_title_line {
          width: 2px;
          height: 13px;
          border-radius: 2px;
          background-color: #000000;
        }

        .detail_view_content_title_text {
          font-size: 11px;
          margin-left: 5px;
          color: #000000;
          font-weight: bold;
          margin-bottom: 1px;
        }
      }

      .detail_view_content_info {
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        margin-left: 30px;
        margin-top: 13px;
        margin-bottom: 20px;

        .detail_view_content_info_h {
          width: 100%;
          display: flex;
          flex-direction: row;
          overflow: hidden;
          margin-top: 7px;

          .detail_view_content_info_h_item {
            flex: 1;
            font-size: 10px;
            color: #000000;
            text-align: left;
          }
        }
      }

      .detail_view_content_debug {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        margin-top: 20px;
        margin-bottom: 20px;

        .debug_view_top_intms {
          display: flex;
          flex-direction: row;
          overflow: hidden;
          align-items: center;
          margin-left: 30px;

          .debug_view_top_input {
            height: 20px;
            font-size: 10px;
            width: 70px;
            margin-right: 5px;
            color: #333333;
          }

          span {
            color: #333333;
            font-size: 10px;
          }
        }

        .debug_bt_view {
          margin-left: 10px;
          padding-left: 10px;
          padding-right: 10px;
          height: 20px;
          line-height: 20px;
          border-radius: 2px;
          color: #ffffff;
          background-color: $colorA;
          font-size: 10px;
          cursor: pointer;
        }

        .debug_bt_view:hover {
          background-color: $colorB;
        }
      }

      .detail_view_content_upgrade {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        margin-bottom: 10px;
        margin-left: 20px;
        padding: 10px;
        overflow-y: scroll;

        .detail_view_content_upgrade_item {
          width: 795px;
          min-height: 50px;
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
          overflow: hidden;
          margin-right: 20px;
          box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.07);
          border-radius: 5px;
          cursor: pointer;
          padding: 10px;

          .detail_view_content_upgrade_item_one {
            width: 100%;
            display: flex;
            flex-direction: row;
            overflow: hidden;
            align-items: center;
          }

          .detail_view_content_upgrade_item_title {
            font-size: 10px;
            font-weight: bold;
            color: #000;
            margin-right: 20px;
          }

          .detail_view_content_upgrade_item_text {
            width: 160px;
            max-width: 160px;
            font-size: 9px;
            color: #000;
            text-align: left;
            margin-left: 10px;
            text-align: left;
          }

          .detail_view_content_upgrade_item_option {
            font-size: 9px;
            color: #000;
            margin-left: 10px;
          }

          .detail_view_content_upgrade_item_option_type {
            width: 150px;
          }

          .detail_view_content_upgrade_item_content {
            font-size: 9px;
            color: #000;
            text-align: left;
            margin-left: 60px;
            margin-top: 10px;
            max-height: 35px;
            overflow: auto;
          }

          .upgrade_bt_view {
            width: 70px;
            height: 20px;
            line-height: 20px;
            border-radius: 2px;
            color: #ffffff;
            background-color: $colorA;
            font-size: 10px;
            cursor: pointer;
            margin-left: 15px;
          }

          .upgrade_bt_view:hover {
            background-color: $colorB;
          }
        }

      }

    }

  }

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  html {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }

  body::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  body::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  body::-webkit-scrollbar-track {
    background-color: transparent;
  }

  body {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }


  .crash_log {
    color: #000;
    text-align: left;

    h1 {
      font-size: 12px;
    }

    p {
      overflow: scroll;
      font-size: 10px;
      margin-top: 10px;
    }
  }
}
</style>
<style>
/* 此处禁止改动，会影响到所有使用时间选择器的地方 */
.el-date-editor.el-input,
.el-date-editor.el-input__wrapper {
  width: 183px !important;
  height: 20px !important;
}

.el-radio__input.is-checked .el-radio__inner {
  background: #0658fe !important;
}

.el-radio-group .el-radio.is-checked .el-radio__label {
  color: #0658fe !important;
}
</style>