<template>
  <div class="parent">
    <div class="tap_option">
      <el-button
        class="btn_tab"
        type="primary"
        icon="el-icon-refresh-right"
        size="small"
        plain
        @click="findUserRoleAll"
      >
        查询</el-button
      >
      <el-button
        class="btn_tab"
        type="primary"
        icon="el-icon-printer"
        size="small"
        plain
        @click="showDrawer(null)"
      >
        新增</el-button
      >
    </div>

    <el-table
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-background="rgba(255, 255, 255, 0.9)"
      :data="
        dataList.slice((currentPage - 1) * pageSize, currentPage * pageSize)
      "
      border
      stripe
      style="width: 100%"
      :max-height="tablesHight"
      align="center"
      size="medium"
      :header-cell-style="{
        background: '#f9f9f9',
        color: '#666666',
        fontSize: '14px',
      }"
      ref="multipleTable"
    >
      <el-table-column
        prop="userName"
        label="姓名"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="account"
        label="账号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="roleName"
        label="角色"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <a class="edit" @click="showDrawer(scope.row)">编辑角色</a>
          <a
            class="delete"
            @click="deleteUserRoleDialog(scope.row)"
            v-if="!scope.row.children"
            >删除</a
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      background
      layout="total,prev,pager,next"
      :pageSize="pageSize"
      :total="total"
      :current-page="currentPage"
      @current-change="handleChange"
    ></el-pagination>
    <el-drawer
      title=""
      v-model="drawerVisible"
      :with-header="false"
      direction="rtl"
      size="25%"
    >
      <div class="drawer_view" v-loading="drawerLoading">
        <div class="drawer_top">
          <span class="drawer_title">{{ drawerTitle }}</span>
          <div class="drawer_top_right">
            <span @click="drawerVisible = false">取消</span>
            <span @click="commit">提交</span>
          </div>
        </div>
        <div class="drawer_item">
          <span>姓名：</span>
          <el-input
            class="drawer_item_input"
            v-model="userName"
            size="small"
          ></el-input>
        </div>
        <div class="drawer_item">
          <span>账号：</span>
          <el-input
            class="drawer_item_input"
            v-model="account"
            size="small"
            :disabled="accountDisable"
          ></el-input>
        </div>
        <div class="tree_item">
          <span class="tree_title">角色：</span>
          <el-select
            v-model="roleList"
            placeholder="请选择"
            class="drawer_item_input"
            multiple
            size="small"
          >
            <el-option
              v-for="item in roleListOptions"
              :key="item.roleId"
              :label="item.name + '(' + item.roleId + ')'"
              :value="item.roleId"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { ElTable, ElTableColumn, ElDrawer,ElOption,ElSelect,ElInput,ElMessage,ElMessageBox } from "element-plus";
import Global from "./../../components/ProjectGlobal";
import Storage from "../../storage/index";
import RequestApi from "./../../components/RequestApi";
// import Utils from "../../util/utils";
import RequestManager from "../../util/request";
export default {
  name:'UserManagement',
  data() {
    return {
      drawerLoading: false, //加载圈
      drawerVisible: false, //是否显示侧边弹框
      currentPage: 1,
      pageSize: 13,
      total: 0, //总数
      loading: false, //加载圈
      dataList: [],
      parentMenuoptions: [],
      tablesHight: 600,
      drawerTitle: "",
      optionType: 0, //0-新增 1-编辑
      account: "",
      userName: "",
      accountDisable: false,
      roleListOptions: [], //所有角色列表
      roleList: [], //选中的角色列表
    };
  },
  components: { ElTable, ElTableColumn, ElDrawer,ElOption,ElSelect,ElInput},
  created() {
    window.addEventListener("resize", this.getHeight); // 注册监听器
    this.getHeight(); // 页面创建时先调用一次
  },
  unmounted() {
    window.removeEventListener("resize", this.getHeight);
  },
  mounted() {
    this.controlCrumb();
    this.findUserRoleAll();
    this.findAllRole();
  },
  methods: {
    //查询所有角色
    findAllRole() {
      RequestManager.hxCloudRequest
        .post(RequestApi.findAllRole, {
          user: Storage.getItem(Global.uid),
          token: Storage.getItem(Global.token),
        })
        .then((res) => {
          if (res.code == 200) {
            this.roleListOptions = res.data;
          } else {
            this.roleListOptions = [];
            ElMessage.error(res.message);
          }
        });
    },
    //查询所有用户绑定的角色
    findUserRoleAll() {
      this.loading = true;
      RequestManager.hxCloudRequest
        .post(RequestApi.findUserRoleAll, {
          user: Storage.getItem(Global.uid),
          token: Storage.getItem(Global.token),
        })
        .then((res) => {
          this.dataList = [];
          if (res.code == 200) {
            var tempList = res.data;
            var tempMap = new Map();
            for (var i = 0; i < tempList.length; i++) {
              var value = tempMap.get(tempList[i].account);
              if (value == null) {
                tempMap.set(
                  tempList[i].account,
                  tempList[i].roleName + "(" + tempList[i].roleId + ")"
                );
              } else {
                tempMap.set(
                  tempList[i].account,
                  value +
                    "," +
                    tempList[i].roleName +
                    "(" +
                    tempList[i].roleId +
                    ")"
                );
              }
            }
            for (var k = 0; k < tempList.length; k++) {
              this.dataList.push({
                userName: tempList[k].userName,
                account: tempList[k].account,
                roleName: tempMap.get(tempList[k].account),
              });
            }
            // tempMap.forEach((value, key) => {
            //   this.dataList.push({
            //     account: key,
            //     roleName: value,
            //   });
            // });
            this.total = this.dataList.length;
          } else {
            this.dataList = [];
            this.total = 0;
            ElMessage.error(res.message);
          }
          this.loading = false;
          this.currentPage = 1;
        });
    },
    //显示侧边弹框
    showDrawer(bean) {
      this.account = "";
      this.userName = "";
      this.roleList = [];
      if (bean != null) {
        this.optionType = 1;
        this.drawerTitle = "编辑用户角色";
        this.account = bean.account;
        this.userName = bean.userName;
        this.accountDisable = true;
        if (bean.roleName.indexOf(",")) {
          var array = bean.roleName.split(",");
          array.forEach((element) => {
            var index = element.indexOf("(");
            this.roleList.push(
              parseInt(element.substring(index + 1, element.length - 1).trim())
            );
          });
        } else {
          var index = bean.roleName.indexOf("(");
          this.roleList.push(
            parseInt(
              bean.roleName
                .substring(index + 1, bean.roleName.length - 1)
                .trim()
            )
          );
        }
      } else {
        this.optionType = 0;
        this.drawerTitle = "新增用户";
        this.accountDisable = false;
      }
      this.drawerVisible = true;
    },
    //设置用户角色
    commit() {
      if (this.userName == "") {
        ElMessage.warning("请输入姓名");
        return;
      }
      if (this.account == "") {
        ElMessage.warning("请输入账号");
        return;
      }
      if (this.roleList.length == 0) {
        ElMessage.warning("请选择角色");
        return;
      }
      var roleIds = "";
      for (var k = 0; k < this.roleList.length; k++)
        roleIds = roleIds + this.roleList[k] + ",";
      if (roleIds.lastIndexOf(",") != -1)
        roleIds = roleIds.substring(0, roleIds.lastIndexOf(","));
      this.drawerLoading = true;
      RequestManager.hxCloudRequest
        .post(RequestApi.setUserRole, {
          user: Storage.getItem(Global.uid),
          token: Storage.getItem(Global.token),
          roleIds: roleIds,
          account: this.account,
          userName: this.userName,
        })
        .then((res) => {
          if (res.code == 200) {
            ElMessage.success("提交成功");
            this.findUserRoleAll();
            this.drawerVisible = false;
          } else {
            ElMessage.error(res.message);
          }
          this.drawerLoading = false;
        });
    },
    //删除弹框
    deleteUserRoleDialog(item) {
      ElMessageBox.confirm(
        "确认删除'" + item.userName + "' 用户绑定的所有角色数据?",
        "提示",
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          customClass: "custom-box",
        }
      )
        .then(() => {
          this.deleteUserRole(item.account);
        })
        .catch(() => {});
    },
    //删除用户绑定的所有角色信息
    deleteUserRole(account) {
      RequestManager.hxCloudRequest
        .post(RequestApi.deleteUserRoleByAccount, {
          user: Storage.getItem(Global.uid),
          token: Storage.getItem(Global.token),
          account: account,
        })
        .then((res) => {
          if (res.code == 200) {
            ElMessage.success("删除成功");
            this.findUserRoleAll();
          } else {
            ElMessage.error(res.message);
          }
        });
    },
    //设置面包屑文案
    controlCrumb() {
      this.$emit("controlCrumb", "首页", "权限管理", "用户管理");
    },
    getHeight() {
      // 获取数据表的最大高度
      this.tablesHight = window.innerHeight - 265;
      window.console.log("tablesHight" + this.tablesHight);
    },
    //切换页码
    handleChange(pageNum) {
      this.currentPage = pageNum;
    },
    //点击刷新按钮
    refreshList(e) {
      let target = e.target;
      // 根据button组件内容 里面包括一个span标签，如果设置icon，则还包括一个i标签，其他情况请自行观察。
      // 所以，在我们点击到button组件上的文字也就是span标签上时，直接执行e.target.blur()不会生效，所以要加一层判断。
      if (target.nodeName == "SPAN" || target.nodeName == "I") {
        target = e.target.parentNode;
      }
      target.blur();
      this.findUserRoleAll();
    },
    //跳转至首页
    navToHome() {
      window.location.href = "http://htopcloud.chinaoak.cn:8080/summary.aspx";
    },
  },
};
</script>
<style  lang="scss" scoped>

body {
  margin: 0;
}

.parent {
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-top: 10px;
  overflow: hidden;

  .tab_page {
    background-color: white;
    padding-left: 10px;
  }

  .tap_option {
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    overflow: auto;
  }

  .pagination {
    text-align: right;
    margin-top: 10px;
  }

  ::v-deep {
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #23c2f7;
    }
  }

  .edit {
    color: #02ace5;
    font-size: 14px;
    margin-right: 20px;
    cursor: pointer;
  }

  .edit:hover {
    color: #0296c7;
  }

  .delete {
    color: #e50202;
    font-size: 14px;
    margin-right: 20px;
    cursor: pointer;
  }

  .delete:hover {
    color: #bd0101;
  }

  .el-button--primary {
    color: #02ade5 !important;
    background-color: #ffffff;
    border-color: #02ade5;

    &:hover {
      background-color: #02ace5;
      color: white !important;
    }
  }

  .drawer_view {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .drawer_top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      margin-top: 10px;
      overflow: hidden;

      .drawer_title {
        color: #333333;
        font-weight: bold;
        font-size: 15px;
        margin-left: 10px;
        overflow: hidden;
      }

      .drawer_top_right {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        align-items: center;
        margin-right: 10px;

        span {
          color: white;
          background-color: #00a4db;
          border-radius: 4px;
          padding-top: 5px;
          padding-bottom: 5px;
          padding-left: 16px;
          padding-right: 16px;
          cursor: pointer;
          margin-left: 10px;
        }
        span:hover {
          background-color: #0097c9;
        }
      }
    }

    .drawer_item {
      width: 100%;
      display: flex;
      flex-direction: row;
      overflow: hidden;
      align-items: center;
      margin-top: 10px;

      span {
        color: #333333;
        font-size: 13px;
        margin-left: 10px;
        width: 50px;
      }

      .drawer_item_input {
        flex: 1;
        margin-right: 10px;
      }
    }

    .tree_item {
      width: 100%;
      display: flex;
      flex-direction: row;
      overflow: hidden;
      margin-top: 10px;

      span {
        color: #333333;
        font-size: 13px;
        margin-top: 6px;
        margin-left: 10px;
        width: 50px;
      }

      .drawer_item_input {
        flex: 1;
        margin-right: 10px;
      }
    }
  }
}
</style>
<style>
/* el-tab 样式修改 */
.el-tabs__item.is-active {
  color: #02ace5;
}

.el-tabs__item:hover {
  color: #02ace5;
}

.el-tabs__active-bar {
  background-color: #02ace5;
}
</style>