<template>
    <transition class="slide">
        <div class="modal" v-show="showModal">
            <div class="mask"></div>
            <div class="modal-dialog" v-loading="showLoading" element-loading-text="加载中，请稍等..."
                element-loading-background="rgba(255, 255, 255, 0.8)">
                <div class="modal-body">
                    <slot name="body"></slot>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name: 'CustomFullPageModal',
    props: {
        showModal: Boolean,
        showLoading: Boolean
    }
}
</script>
<style lang="scss">
@import '../assets/scss/config.scss';
@import '../assets/scss/mixin.scss';
@import '../assets/scss/customFullPageModal.scss';
</style>
