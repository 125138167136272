<template>
  <div class="parent">
    <img
      class="bg_image"
      src=".././assets/image/login_bg.png"
      mode="scaleToFill"
      alt=""
    />
    <div class="content">
      <div class="left">
        <img
          class="left_img"
          src=".././assets/image/login_left_bg.png"
          mode="aspectFit"
          alt=""
        />
      </div>
      <div class="right">
        <span class="right_title"></span>
        <div class="right_item">
          <img
            class="icon"
            src=".././assets/image/acount_icon.png"
            mode="scaleToFill"
            alt=""
          />
          <input class="input" type="text" placeholder="请输入账号" />
        </div>
        <div class="line"></div>
        <div class="right_item">
          <img
            class="icon"
            src=".././assets/image/ps_icon.png"
            mode="scaleToFill"
            alt=""
          />
          <input class="input" type="password" placeholder="请输入密码" />
        </div>
        <div class="line"></div>
        <el-button
          class="login"
          color="#626aef"
          size="small"
          :loading="isLoading"
          >登 录</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { ElButton, ElMessage, ElInput } from "element-plus";
import RequestManager from "../util/request";
import CommonUtil from "../util/commonUtil";
import Storage from "./../storage/index";
import RequestApi from "../components/RequestApi";
import Global from "../components/ProjectGlobal";
export default {
  name: "login",
  data() {
    return {
      isLoading: false,
      phone: "",
      password: "",
      themeVars: {
        buttonSmallHeight: "45px",
        buttonSmallFontSize: "18px",
        buttonBorderRadius: "18px",
      },
    };
  },
  components: {},
  methods: {
    //登录逻辑处理
    login() {
      
    },
    //查询单个用户绑定的角色
    findUserRoleByAccount(token, user) {
     
    },
    //获取用户信息
    getUserInfo(token, user) {
      
    },
    //校验用户账号密码
    checkInput() {
      if (this.phone.length == 0) {
        ElMessage({
          message: "请输入账号",
          type: "warning",
        });
        return false;
      }
      if (this.password.length == 0) {
        ElMessage({
          message: "请输入密码",
          type: "warning",
        });
        return false;
      }
      return true;
    },
    //通知原生APP登录成功
    noticeAppLoginSuccess(token, uid) {
      try {
        if (Global.phoneType == 1) {
          //Android端
          window.WebAppInterface.webToAppLoginSuccess(token, uid);
        } else if (Global.phoneType == 2) {
          //IOS端
        }
      } catch (e) {
        window.console.log(e);
      }
    },
  },
};
</script> 
<style scoped lang="scss">
@import ".././assets/scss/base.scss";
input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
}

body {
  width: 100%;
  height: 100%;
}

.parent {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;

  .bg_image {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .content {
    width: 40%;
    height: 50%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    z-index: 1;
    box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.5);
    background-color: white;
    border-radius: 4px;
    align-items: center;

    .left {
      display: flex;
      flex: 1;
      height: 100%;
      overflow: hidden;
      align-items: center;
      justify-content: center;

      .left_img {
        width: 80%;
      }
    }

    .right {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .right_title {
        font-weight: bold;
        color: $colorA;
        font-size: 22px;
        margin-top: 10px;
        font-family: "Courier New", Courier, monospace;
        margin-bottom: 20px;
      }

      .right_item {
        width: 150px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;
        .icon {
          width: 12px;
        }
        .input {
          margin-left: 5px;
          width: 100%;
          border-width: 0px;
          height: 20px;
          background-color: #ffffff;
          color: #222222;
          font-size: 12px;
        }
      }

      .line {
        width: 150px;
        height: 1px;
        background-color: #dddddd;
      }

      .login {
        margin-top: 25px;
        width: 150px;
        height: 25px;
        color: #ffffff;
        background-color: $colorA;
      }
    }
  }
}
</style>
