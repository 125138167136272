<template>
  <div class="parent">
    <div class="left">
      <img class="logo_img" src=".././assets/image/xz_logo.png" alt="" />
      <div class="menu">
        <div v-for="(firstItem, index) in menuList" :key="index">
          <div class="first_item" @click="menuClick(firstItem)" :style="{
            backgroundColor:
              clickMenuId == firstItem.menuId ? '#2f71fd' : '#0658fe',
          }">
            <component :is="firstItem.menuIcon" class="first_item_one_img"></component>
            <span>{{ firstItem.menuName }}</span>
            <img v-if="firstItem.children.length >= 1" class="first_item_two_img" src=".././assets/image/down_white.png"
              alt="" />
          </div>
          <!-- <template v-if="openParentMemuId == firstItem.menuId"> -->
          <div class="second_item" v-for="(secondItem, index) in firstItem.children" :key="index"
            @click="menuClick(secondItem)" :style="{
              backgroundColor:
                clickMenuId == secondItem.menuId ? '#2f71fd' : '#0658fe',
            }">
            <div class="v_line" :style="{
              backgroundColor:
                clickMenuId == secondItem.menuId ? '#ffffff' : '#00000000',
            }"></div>
            <!-- <component :is="secondItem.menuIcon" class="second_item_img"></component> -->
            <span>{{ secondItem.menuName }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="right_top">
        <div class="right_top_left">
          <span class="right_top_left_text">熙臻云端监控中心</span>
          <span class="right_top_left_text_1">{{ env }}</span>
        </div>
        <div class="right_top_right">
          <img class="user_icon" src=".././assets/image/down_gray.png" mode="scaleToFill" alt="" />
          <span>管理员</span>
          <img class="exit_icon" src=".././assets/image/exit_gray.png" mode="scaleToFill" alt="" />
          <span>退出</span>
        </div>
      </div>
      <div class="content">
        <div class="content_top">
          <el-tabs v-model="currentTab" type="card" class="my_tabs" closable @tab-remove="removeTab"
            @tab-click="clickTab">
            <el-tab-pane v-for="item in tabList" :key="item.name" :label="item.title" :name="item.name">
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="content_bottom">
          <router-view v-slot="{ Component }">
            <keep-alive>
              <component :is="Component" />
            </keep-alive>
          </router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ElTabs, ElIcon } from "element-plus";
import { Edit } from "@element-plus/icons-vue";
import commonUtil from "../util/commonUtil.js";
import Storage from "../storage/index";
import Global from "../components/ProjectGlobal";
import RequestApi from "../components/RequestApi";
import RequestManager from "../util/request.js";
import envJs from '../env.js'
// import { el } from "element-plus/es/locale/index.js";
export default {
  name: "Home",
  data() {
    return {
      openParentMemuId: 0, //当前展开的一级菜单ID
      currentTab: 1, //当前点击的tab，对应菜单ID
      clickMenuId: 0, //当前点击的菜单ID
      currentPath: "", //当前路由
      env: "",
      menuList: [
        {
          menuId: 1,
          menuName: "设备管理",
          menuUrl: "",
          menuIcon: "Monitor",
          parentMenuId: -1,
          children: [
            {
              menuId: 11,
              menuName: "设备列表",
              menuUrl: "DeviceList",
              menuIcon: "Postcard",
              parentMenuId: 1,
              children: [],
            },
            {
              menuId: 12,
              menuName: "调试数据",
              menuUrl: "DeviceDebugData",
              menuIcon: "Postcard",
              parentMenuId: 1,
              children: [],
            },
            {
              menuId: 13,
              menuName: "固件上传",
              menuUrl: "FirmwareManagement",
              menuIcon: "ShoppingBag",
              parentMenuId: 1,
              children: [],
            },
            {
              menuId: 14,
              menuName: "固件列表",
              menuUrl: "FirmwareRecords",
              menuIcon: "Wallet",
              parentMenuId: 1,
              children: [],
            },
            {
              menuId: 15,
              menuName: "固件上传(Matter版)",
              menuUrl: "MatterOtaFileUpload",
              menuIcon: "FolderAdd",
              parentMenuId: 1,
              children: [],
            },
            {
              menuId: 16,
              menuName: "固件列表(Matter版)",
              menuUrl: "MatterOtaFileRecords",
              menuIcon: "Wallet",
              parentMenuId: 1,
              children: [],
            },
            {
              menuId: 17,
              menuName: "固件上传(点对点)",
              menuUrl: "FirmwareManagementPTP",
              menuIcon: "FolderAdd",
              parentMenuId: 1,
              children: [],
            },
            {
              menuId: 18,
              menuName: "固件列表(点对点)",
              menuUrl: "FirmwareRecordsPTP",
              menuIcon: "Wallet",
              parentMenuId: 1,
              children: [],
            },
          ],
        },
        {
          menuId: 2,
          menuName: "APP管理",
          menuUrl: "",
          menuIcon: "Files",
          parentMenuId: -1,
          children: [
            {
              menuId: 21,
              menuName: "APP包上传",
              menuUrl: "AppPackageUpload",
              menuIcon: "FolderAdd",
              parentMenuId: 2,
              children: [],
            },
            {
              menuId: 22,
              menuName: "APP包列表",
              menuUrl: "AppPackageRecords",
              menuIcon: "Wallet",
              parentMenuId: 2,
              children: [],
            },
          ],
        },
        {
          menuId: 3,
          menuName: "用户管理",
          menuUrl: "",
          menuIcon: "Place",
          parentMenuId: -1,
          children: [
            {
              menuId: 31,
              menuName: "用户列表",
              menuUrl: "UserList",
              menuIcon: "Place",
              parentMenuId: 3,
              children: [],
            },
          ],
        },
      ], //菜单列表
      tabList: [],
    };
  },
  components: { Edit },
  mounted() {
    this.env = envJs.buildType == "production" ? "正式环境" : "测试环境"
    var url = window.location.href;
    let from = this.$route.query.from; //1-来自登录页跳转
    let user = this.$route.query.uid;
    let token = this.$route.query.token;
    let menuName = '';
    let ctb = 1;
    let cmd = 0;
    if (from != 1) {
      //页面刷新
      var currentPath = Storage.getItem(Global.currentPath);
      var clickMenuId = Storage.getItem(Global.clickMenuId);
      var tabsJson = Storage.getItem(Global.tabsJson);
      var currentTab = Storage.getItem(Global.currentTab);
      var openParentMemuId = Storage.getItem(Global.openParentMemuId);
      if (currentPath != null) {
        this.currentPath = currentPath;
      } else {
        this.currentPath = "DeviceDebugData";
        for (var k = 0; k < this.menuList.length; k++) {
          if (url.indexOf(this.menuList[k].menuUrl) != -1 && this.menuList[k].menuUrl.length > 0) {
            this.currentPath = this.menuList[k].menuUrl;
            this.currentTab = this.menuList[k].menuId;
            this.clickMenuId = this.currentTab;
            this.openParentMemuId = this.currentTab;
            menuName = this.menuList[k].menuName;
            break;
          }
          for (var p = 0; p < this.menuList[k].children.length; p++) {
            if (url.indexOf(this.menuList[k].children[p].menuUrl) != -1) {
              this.currentPath = this.menuList[k].children[p].menuUrl;
              this.currentTab = this.menuList[k].children[p].menuId;
              this.clickMenuId = this.currentTab;
              this.openParentMemuId = this.menuList[k].menuId;
              menuName = this.menuList[k].children[p].menuName;
              break;
            }
          }
        }
      }
      // if (openParentMemuId != null) {
      //   this.openParentMemuId = openParentMemuId;
      // } else {
      //   this.openParentMemuId = 0;
      // }
      // if (currentTab != null) {
      //   this.currentTab = currentTab;
      // } else {
      //   this.currentTab = ctb;
      // }
      // if (clickMenuId != null) {
      //   this.clickMenuId = clickMenuId;
      // } else {
      //   this.clickMenuId = cmd;
      // }
      window.console.log("currentPath:" + this.currentPath);
      window.console.log("clickMenuId:" + this.clickMenuId);
      window.console.log("currentTab:" + this.currentTab);
      if (tabsJson != null) {
        var tabs = JSON.parse(tabsJson);
        if (tabs != null && tabs.length > 0) {
          this.tabList = tabs;
        }
      } else {
        this.tabList.push({
          title: menuName,
          name: this.clickMenuId,
          content: this.currentPath,
        });
      }
    }
    window.console.log(this.tabList);

    if (user != null && user.length > 0 && token != null && token.length > 0) {
      Storage.setItem(Global.token, token);
      Storage.setItem(Global.uid, user);
    }
    //设置禁止回退
    history.pushState(null, null, document.URL);
    window.addEventListener(
      "popstate",
      function () {
        history.pushState(null, null, document.URL);
      },
      false
    );
  },
  watch: {
    // 监听路由对象中的变化
    '$route': {
      handler: function (to, from) {
        // 路由发生变化时会调用这个函数
        // 在这里可以根据路由变化执行相应的逻辑
        console.log('Route changed from', from.name, 'to', to.name);
        var menuInfo = this.getMenuInfo(to.name)
        if (menuInfo != null) {
          this.clickMenuId = menuInfo.menuId;
          this.currentTab = menuInfo.menuId;
          Storage.setItem(Global.clickMenuId, this.clickMenuId);
          Storage.setItem(Global.currentTab, this.currentTab);
          if (menuInfo.parentMenuId == -1) {
            //点击的是一级菜单
            this.openParentMemuId = this.openParentMemuId != menuInfo.menuId ? menuInfo.menuId : -1;
            Storage.setItem(Global.openParentMemuId, this.openParentMemuId);
          }
        }
      },
      // 设置为深度监听
      deep: true
    }
  },
  methods: {
    //菜单点击事件
    menuClick(item) {
      if (
        (item.parentMenuId == -1 && item.children.length == 0) ||
        item.parentMenuId != -1
      ) {
        // this.clickMenuId = item.menuId;
        // Storage.setItem(Global.clickMenuId, this.clickMenuId);
        this.addTab({
          title: item.menuName,
          name: item.menuId,
          content: item.menuUrl,
        });
        this.$router.push({
          name: item.menuUrl,
          query: {},
        });
      }
      // if (item.parentMenuId == -1) {
      //   //点击的是一级菜单
      //   this.openParentMemuId =
      //     this.openParentMemuId != item.menuId ? item.menuId : -1;
      //   Storage.setItem(Global.openParentMemuId, this.openParentMemuId);
      // }
    },
    //点击tab
    clickTab(event) {
      const convert = JSON.parse(JSON.stringify(event.props));
      window.console.log("convert====>" + convert);
      // this.currentTab = convert.name;
      // this.clickMenuId = convert.name;
      // Storage.setItem(Global.currentTab, this.currentTab);
      // Storage.setItem(Global.clickMenuId, this.clickMenuId);
      // var parentId = this.getParentMenuId(convert.name);
      // window.console.log("parentId=======>" + parentId);
      // if (parentId != -1) {
        // this.openParentMemuId = parentId;
        // Storage.setItem(Global.openParentMemuId, parentId);
      // }
      var menuUrl = this.getMenuUrl(convert.name);
      if (menuUrl.length > 0)
        this.$router.push({
          name: menuUrl,
          query: {},
        });
    },
    //添加tab
    addTab(targetItem) {
      // this.currentTab = targetItem.name;
      // Storage.setItem(Global.currentTab, this.currentTab);
      if (this.tabList.some((item) => item.name === targetItem.name)) {
        //tab列表已经包含目标tab,则直接定位到目标tab页
      } else {
        //未包含，则添加
        this.tabList.push(targetItem);
        Storage.setItem(Global.tabsJson, JSON.stringify(this.tabList));
      }
    },
    //关闭tab
    removeTab(targetName) {
      window.console.log("removeTab:" + targetName);
      var tempList = this.tabList;
      tempList = tempList.filter((item) => item.name != targetName);
      if (tempList.length == 0) {
        //如果只有一个tab，则默认展示首页
        tempList.push({
          title: "设备列表",
          name: 11,
          content: "DeviceList",
        });
        this.$router.push({
          name: "DeviceList",
          query: {},
        });
      }
      // this.currentTab = tempList[tempList.length - 1].name;
      // this.clickMenuId = this.currentTab;
      var menuUrl = tempList[tempList.length - 1].content;
      this.tabList = tempList;
      Storage.setItem(Global.tabsJson, JSON.stringify(this.tabList));
      this.$router.push({
        name: menuUrl,
        query: {},
      });
    },
    //获取父级菜单ID
    getParentMenuId(childrenMenuId) {
      for (var k = 0; k < this.menuList.length; k++) {
        var menuId = this.menuList[k].menuId;
        if (
          menuId == childrenMenuId ||
          (childrenMenuId + "").indexOf(menuId + "") == 0
        ) {
          return menuId;
        }
      }
      return -1;
    },
    //根据menuId获取菜单链接
    getMenuUrl(menuId) {
      for (var k = 0; k < this.menuList.length; k++) {
        if (this.menuList[k].menuId == menuId) return this.menuList[k].menuUrl;
        for (var p = 0; p < this.menuList[k].children.length; p++) {
          if (this.menuList[k].children[p].menuId == menuId)
            return this.menuList[k].children[p].menuUrl;
        }
      }
      return "";
    },
    //根据菜单链接获取菜单信息
    getMenuInfo(menuUrl) {
      for (var k = 0; k < this.menuList.length; k++) {
        if (this.menuList[k].menuUrl == menuUrl) return this.menuList[k];
        for (var p = 0; p < this.menuList[k].children.length; p++) {
          if (this.menuList[k].children[p].menuUrl == menuUrl)
            return this.menuList[k].children[p];
        }
      }
      return null;
    },
  },
};
</script>
<style scoped lang="scss">
@import ".././assets/scss/base.scss";

body {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}

.parent {
  width: 100%;
  height: 100%;
  background-color: $colorC;
  display: flex;
  flex-direction: row;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
  overflow-y: hidden;

  .left {
    width: 130px;
    height: 100%;
    background-color: $colorA;
    display: flex;
    flex-direction: column;

    .logo_img {
      width: 75px;
      height: 10px;
      align-self: center;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .menu {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      background-color: $colorA;

      .first_item {
        width: 100%;
        height: 25px;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        align-items: center;
        font-size: 10px;
        color: white;
        padding-left: 10px;
        margin-bottom: 1px;
        cursor: pointer;

        .first_item_one_img {
          width: 13px;
          height: 13px;
        }

        .first_item_two_img {
          width: 7px;
          height: 7px;
          margin-left: 10px;
        }

        span {
          margin-left: 5px;
          width: 60px;
          text-align: left;
        }
      }

      .first_item:hover {
        background-color: $colorB !important;
      }

      .second_item {
        width: 100%;
        height: 25px;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        align-items: center;
        font-size: 9px;
        color: white;
        margin-bottom: 1px;
        cursor: pointer;

        .v_line {
          height: 25px;
          width: 2px;
          margin-right: 25px;
          background-color: white;
          border-radius: 5px;
          margin-left: 1px;
        }

        .second_item_img {
          width: 11px;
          height: 11px;
          margin-right: 5px;
        }
      }

      .second_item:hover {
        background-color: $colorB !important;
      }
    }
  }

  .right {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;

    .right_top {
      height: 30px;
      width: 100%;
      display: flex;
      flex-direction: row;
      overflow: hidden;
      background-color: white;
      align-items: center;

      .right_top_left {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        align-items: center;
        margin-left: 10px;

        .right_top_left_text {
          font-size: 13px;
          color: #000000;
          font-weight: bold;
          letter-spacing: 1px;
        }

        .right_top_left_text_1 {
          margin-top: 2px;
          font-size: 10px;
          color: #999898;
          letter-spacing: 1px;
          background-color: #eeeeeea9;
          margin-left: 5px;
          padding-left: 5px;
          padding-right: 5px;
          border-radius: 2px;
          padding-top: 1px;
          padding-bottom: 1px;
        }
      }

      .right_top_right {
        display: flex;
        flex: 1;
        flex-direction: row;
        margin-right: 10px;
        text-align: right;
        align-items: center;
        justify-content: flex-end;

        .user_icon {
          width: 6px;
          height: 6px;
          cursor: pointer;
        }

        .exit_icon {
          width: 11px;
          height: 11px;
          margin-left: 10px;
          cursor: pointer;
        }

        span {
          color: #333333;
          font-size: 9px;
          padding-left: 3px;
          cursor: pointer;
        }
      }
    }

    .content {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      background-color: $colorC;
      margin-top: 5px;

      .content_top {
        width: 100%;
        height: 30px;
        padding-top: 5px;
        padding-left: 5px;
        background-color: white;
        margin-left: 1px;
      }

      .content_bottom {
        width: 100%;
        flex: 1;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  html {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }

  body::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  body::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  body::-webkit-scrollbar-track {
    background-color: transparent;
  }

  body {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }

}
</style>
<style>
/* 只改变特定 .el-tabs 下的 .el-tabs__item 的字体大小 */
.my_tabs .el-tabs__item {
  font-size: 10px !important;
  /* 根据需求设置字体大小 */
  height: 24px !important;
  line-height: 24px !important;
  padding-top: 2px !important;
  padding-left: 9px !important;
  padding-right: 5px !important;
}

.my_tabs .el-tabs__item.is-active {
  color: #2f71fd;
}

.my_tabs .el-tabs__item:hover {
  color: #2f71fd;
  cursor: pointer;
}

.my_tabs .el-tabs {
  height: 24px !important;
  line-height: 24px;
}

.my_tabs .el-tabs__header {
  height: 24px !important;
  line-height: 24px !important;
}

.my_tabs .el-tabs__active-bar {
  display: none !important;
}

.my_tabs .el-tabs__nav-prev {
  height: 24px !important;
  line-height: 24px !important;
  margin-top: 2px;
}

.my_tabs .el-tabs__nav-wrap {
  height: 24px !important;
  line-height: 24px !important;
}

.my_tabs .el-tabs__nav-next {
  height: 24px !important;
  line-height: 24px !important;
  margin-top: 2px;
}

.my_tabs i.el-icon.is-icon-close {
  color: #bbbbbb !important;
}
</style>
