<template>
  <!-- 隐私协议-->
  <div class="parent">
    <div class="content">
      <span class="title">长沙熙臻电子科技有限公司用户隐私条款</span>
      <span class="privacy">
        更新日期：2024年9月1日
        <br>生效日期：2024年9月3日
        <br><br>
        本软件尊重并保护用户个人隐私权。本软件会按照本隐私权政策的规定使用和披露您的个人信息。但本软件将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，本软件不会将这些信息对外披露或向第三方提供。本软件会不时更新本隐私权政策。您在同意本软件服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于本软件服务使用协议不可分割的一部分。
        <br><br>
        <sapn class="sub_title">1.适用范围</sapn>
        <br><br> 在您使用本软件网络服务，本软件自动接收并记录您的手机上的信息，包括但不限于您的健康数据、使用的语言、访问日期和时间、软硬件特征信息等数据；
        <br><br>
        <sapn class="sub_title">2.信息的使用</sapn>
        <br><br>在获得您的数据之后，本软件会将其上传至服务器，以生成您的个人数据，以便您能够更好地使用服务。
        <br><br>
        <sapn class="sub_title">3.信息披露</sapn>
        <br><br>a)本软件不会将您的信息披露给不受信任的第三方。
        <br>b)根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
        <br>c)如您出现违反中国有关法律、法规或者相关规则的情况，需要向第三方披露；
        <br><br>
        <sapn class="sub_title">4.信息存储和交换</sapn>
        <br><br>本软件收集的有关您的信息和资料将保存在本软件及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本软件收集信息和资料所在地的境外并在境外被访问、存储和展示。
        <br><br>
        <sapn class="sub_title">5.信息安全</sapn>
        <br><br>在使用本软件网络服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，请您立即联络本软件客服，以便本软件采取相应措施。
        <br><br>
        <sapn class="sub_title">6.收集和使用</sapn>
        <br><br>a)在用户同意应用获取手机号的情况下，在app内拨打客服电话；在用户同意应用获取您的设备信息（Android ID/ Android序列号）情况下，用于app异常崩溃时收集设备信息（Android ID/
        Android序列号）;
        <br>b)在用户同意应用获取相机权限、定位权限、蓝牙权限情况下，在app内打开相机，扫码连接蓝牙设备；在用户同意获取软件安装列表情况下，用于app自身应用在主进程中初始化崩溃日志SDK，不涉及读取其他应用信息；
        <br>c)在用户同意应用读取存储卡上的照片、媒体内容和文件情况下，在app内选择相册设置用户头像；
        <br>d)第三方SDK目录：
        <br><br> 腾讯Bugly SDK
        <br>第三方名称：深圳市腾讯计算机系统有限公司
        <br>使用目的：用于获取"Gerlsair"APP运行过程中出现的崩溃日志
        <br>收集个人信息：设备标识信息、应用使用信息
        <br><br>
        <sapn class="sub_title">7.如何联系我们</sapn>
        <br><br>如您对个人信息保护问题有任何投诉、建议、疑问，或您对本政策有任何疑问，您都可以通过以下方式联系我们：xunpz@xizhenelec.com
        <br><br><br><br>
        <sapn class="company">长沙熙臻电子科技有限公司</sapn>
        <br><br><br><br>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "PrivacyAgreement",
  data() {
    return {
    };
  },
  components: {},
  mounted() {

  },
  created() {
    document.title = '熙臻科技用户隐私条款'; // 设置页面标题
    window.addEventListener("resize", this.getHeight); // 注册监听器
    this.getHeight(); // 页面创建时先调用一次
  },
  unmounted() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    getHeight() {
      //获取数据表的最大高度
      this.tablesHight = window.innerHeight - 150;
      this.windowHeight = window.innerHeight;
    },
  },
};
</script>
<style scoped lang="scss">
@import ".././assets/scss/base.scss";
@import ".././assets/css/common.css";

body {
  width: 100%;
  height: 100%;
}

.parent {
  width: 100%;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    .title {
      margin-top: 15px;
      font-size: 14px;
      color: black;
      font-weight: bold;
    }

    .privacy {
      font-size: 12px;
      margin-top: 15px;
      color: #303030;
      margin-left: 20px;
      margin-right: 20px;
      text-align: left;
    }

    .sub_title {
      font-size: 12px;
      color: #303030;
      font-weight: bold;
    }

    .company {
      font-size: 12px;
      color: #303030;
      font-weight: bold;
    }
  }


  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  html {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }

  body::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  body::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  body::-webkit-scrollbar-track {
    background-color: transparent;
  }

  body {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }
}
</style>