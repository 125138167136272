import md5 from 'js-md5';
export default {

    //判断是否为手机端
    isMobile() {
        let flag = navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
        return flag;
    },

    //判断是否为ios端访问
    isIos() {
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
            return true;
        } else {
            return false;
        }
    },

    //判断是否为android端访问
    isAndroid() {
        if (/(Android|Adr)/i.test(navigator.userAgent)) {
            return true;
        } else {
            return false;
        }
    },

    //时间格式转换
    dateFormat(date) {
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        return year + "-" + month + "-" + day;
    },

    //MD5加密转大写
    getMD5Screct(sign) {
        let screct = md5(sign)
        let bigScrect = screct.toUpperCase()
        return bigScrect
    },

    //解析通信方式
    parseCommunication(type) {
        if (type == "0") {
            return "串口通信";
        } else if (type == "1") {
            return "TCP通信";
        } else if (type == "2") {
            return "LORA通信";
        } else {
            return "未知";
        }
    },

    //获取Android项目DB文件名
    getAndroidDBName(appId) {
        if (appId == 'HtopAndroidDevice' || appId == 'OakIndoorClimateSystem') {
            //Android中控五恒系统
            return "indoorclimate.db";
        } else if (appId == 'HsfpAndroidDevice' || appId == 'HsfpAndroidDevice.hx_mini' || appId == 'HsfpAndroidDevice.Mini') {
            //Android中控新风系统、Android小中控新风系统
            return "HX_Fresh_Air_System.db";
        } else if (appId == 'HtopAndroidDevice.ID58') {
            //Android中控地下室除湿系统
            return "dehumidity.db";
        }
    },

    //获取日期
    getDateStr(ms) {
        var date = new Date(ms);
        var year = date.getFullYear();    //  返回的是年份
        var month = (date.getMonth() + 1) + "";  //  返回的月份上个月的月份，记得+1才是当月
        var dates = date.getDate() + "";       //  返回的是几号
        var newMonth = month.length == 1 ? ("0" + month) : month;
        var newDate = dates.length == 1 ? ("0" + dates) : dates;
        return year + "-" + newMonth + "-" + newDate;
    },


    compare(propertyName, sort) {
        return function (obj1, obj2) {
          var value1 = obj1[propertyName]
          var value2 = obj2[propertyName]
          if (typeof value1 === 'string' && typeof value2 === 'string') {
            const res = value1.localeCompare(value2, 'zh')
            return sort === 'ascending' ? res : -res
          } else {
            if (value1 <= value2) {
              return sort === 'ascending' ? -1 : 1
            } else if (value1 > value2) {
              return sort === 'ascending' ? 1 : -1
            }
          }
        }
      },

}