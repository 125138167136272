/* 封装axios用于发送请求 */
import axios from 'axios'
import qs from 'qs'
import env from '.././env'
import RequestApi from ".././components/RequestApi";
import { ElMessage } from "element-plus";

const xzRequest = axios.create({
    baseURL: (env.buildType == "production" || env.buildType == "test") ?
        (env.buildType == "production" ? "https://cloud.rpc.xizhenelec.com/xz" : "https://cloud.rpc-test.xizhenelec.com/xz") : "/xz",
    timeout: 180000
})


xzRequest.defaults.withCredentials = false;
// xzRequest.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

//xzRequest请求拦截
xzRequest.interceptors.request.use(config => {
    // var token = Storage.getItem('token');
    // if (token) {
    //   config.headers.accessToken = token;
    // }
    if (config.method == 'post') {
        config.headers['Content-Type'] = 'application/json';
    }
    if (config.type == 'formData' || config.method == 'post' || config.method != 'post' || config.url.indexOf(RequestApi.uploadFiemware) != -1 || config.url.indexOf(RequestApi.uploadAppFile) != -1) {
        return config
    }
    config.data = qs.stringify(config.data)
    return config
}, (err) => {
    return Promise.reject(err);
})

//xzRequest接口错误拦截
xzRequest.interceptors.response.use(function (response) { //拦截业务请求
    let res = response.data;
    if (res.state == 12) { // 约定12为未登录，跳转登录界面
        // window.location.href = env.buildType == "production" ? "http://app.chinaoak.cn:8081/#/login" : "http://localhost:8083/#/login";
    } else {
        return res;
    }
}, (error) => {  //拦截http请求报错
    ElMessage.error(error.message);
    return Promise.reject(error);
});

export default { xzRequest } 